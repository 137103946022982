import { Text, Box, Container, Stack, Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";
import { useLangContext } from "configs/languageContext";
import { header } from "data/translations";
import { Fragment, PropsWithChildren, ReactNode } from "react";
import { LanguageSelector } from "components/shared/languageSelector";
import { Link } from "react-router-dom";

interface menuProps { href?: any, isexternal: boolean }

export const StandardLink = (props: PropsWithChildren<menuProps>) => (
    <Box
        {...props}
        as={props.href ? Link : undefined}
        to={props.href ? props.href : undefined}
        target={props.isexternal ? 'blank' : undefined}

        _hover={{ textDecor: 'none', color: 'mcgill_red.500' }}
        transition={'all 0.5s ease'}
        fontSize={'small'}
        fontWeight='medium' textTransform={'uppercase'}
    >
        {props.children}
    </Box >
)

export const HeaderLinks = () => {
    const { lang } = useLangContext();
    const header_links: any = {
        'homepage': 'https://salvemonos.org/',
        'about': 'https://salvemonos.org/about-us/',
        'proj': 'https://salvemonos.org/projects/',
        'blog': 'https://salvemonos.org/blog/',
        'contact': 'https://salvemonos.org/contact/',
        'donate': 'https://salvemonos.org/donate-now/'
    }
    return (
        <>
            {Object.keys(header_links).map((key: string) => (
                <StandardLink key={key} href={header_links[key]} isexternal={true}>{header[key][lang]}</StandardLink>
            ))}
            <LanguageSelector />
        </>
    )
}

export const BreadCrumbLinks = () => {
    const { lang } = useLangContext();
    return (
        <Breadcrumb color='white'>
            <BreadcrumbItem>
                <StandardLink href={'https://salvemonos.org/'} {...{ color: 'white' }} isexternal={false}>{header.homepage[lang]}</StandardLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
                <StandardLink href={'https://salvemonos.org/projects'} {...{ color: 'white' }} isexternal={false}>{header.proj[lang]}</StandardLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
                <StandardLink href={'/'} {...{ color: 'white' }} isexternal={false}>{header.head[lang]}</StandardLink>
            </BreadcrumbItem>
        </Breadcrumb>
    )
}
