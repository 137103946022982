import { inputAnatomy } from '@chakra-ui/anatomy'
import { extendTheme, defineStyleConfig, createMultiStyleConfigHelpers } from "@chakra-ui/react"
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';

// Supports weights 100-900
import '@fontsource-variable/montserrat';

// CUSTOMIZE COMPONENTS
const Text = defineStyleConfig({
    baseStyle: {
        font: 'Montserrat Variable',
        color: 'text_black'
    }
})

const Heading = defineStyleConfig({
    baseStyle: {
        font: 'Montserrat Variable',
        color: 'text_black'
    }
})

const Divider = defineStyleConfig({
    defaultProps: { color: 'red' }
})

const Button = defineStyleConfig({
    variants: {
        brandSolid: {
            bg: 'charcoal.500',
            _hover: { 'bg': 'charcoal.200' },
            color: 'white',
            rounded: 'none',
            fontSize: 24
        },
        brandOutline: {
            bg: 'white',
            border: '2px',
            borderColor: 'charcoal.500',
            _hover: { 'bg': 'charcoal.50' },
            rounded: 'none',
            fontSize: 16,
        },

    }
})

export const theme = extendTheme({
    components: { Text, Heading, Button, Divider },
    colors: {
        mcgill_red: {
            _defaul: '#ed1b2f',
            50: '#fde7ea', 100: '#fbd0d4', 200: '#f7a1a9', 300: '#f4717e', 400: '#f04254',
            500: '#ed1b2f', 600: '#bd0f21', 700: '#8e0b18', 800: '#5e0810', 900: '#2f0408'
        }, // accents
        burgundy: {
            _default: '#8C0F1E',
            50: "#ec465a",
            100: "#e71830",
            200: "#d0162b",
            300: "#b91327",
            400: "#a21122",
            500: "#8C0F1E",
            600: "#730c18",
            700: "#5c0a13",
            800: "#45070e",
            900: "#2e050a",
        }, // buttons
        charcoal: {
            _default: "#323C46",
            50: "#c3ccd5",
            100: "#a5b3c0",
            200: "#6a8095",
            500: "#323C46",

        }, // use for title

        sand: {
            _default: "#D2BEAA",
            50: "#f6f2ee", 100: "#ede6de"
        }, // background
        soft_blue: "#C8D2EB",
        blue: '#AAB4EB',
        dusk: "#F5D7DC", // for sidebar
        text_black: "#000000", // for fonts
    }
})