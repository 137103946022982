import { Card, CardHeader, CardBody, Heading, Divider } from "@chakra-ui/react";
import { useLangContext } from "configs/languageContext";
import { translation } from "data/translations";
import { Fragment, PropsWithChildren, ReactNode } from "react";
import { LanguageSelector } from "./languageSelector";

interface props {
  header?: string;
}
export const StandardCard = (props: PropsWithChildren<props>) => (
  <Card
    shadow="2xl"
    w="100%"
    rounded={"none"}
    border="8px"
    borderColor={"charcoal.500"}
    {...props}
  >
    {props.header && (
      <CardHeader borderBottom={""}>
        <Heading
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
          transition={"all 0.5s ease"}
          textTransform={"capitalize"}
          wordBreak={"break-word"}
          textAlign={'center'}
        >
          {props.header}
        </Heading>
      </CardHeader>
    )}

    <CardBody>{props.children}</CardBody>
  </Card>
);
