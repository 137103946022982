import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  IconButton,
  StackDivider,
} from "@chakra-ui/react";
import {
  Box,
  VStack,
  HStack,
  Spacer,
  Heading,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { Button, CloseButton, Select, Textarea } from "@chakra-ui/react";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { Wrap, WrapItem } from "@chakra-ui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import LocationPicker from "components/shared/locationPicker";
import { useLangContext } from "configs/languageContext";
import { form, form_fields } from "data/translations";
import { accident } from "data/data";
import { TemplateForm } from "components/layout/template";
import { StandardCard } from "components/shared/card";
import { BiTrain, BiTrash } from "react-icons/bi";
import { useDataContext } from "configs/dataContext";
import { Link } from "react-router-dom";
import processFormData from "./processFormData";

export default function PublicFormPage() {
  // LANGUAGE
  const { lang } = useLangContext();
  const { addData } = useDataContext();

  // ALERTS
  const {
    isOpen: isVisibleFail,
    onClose: onCloseFail,
    onOpen: onOpenFail,
  } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isVisibleSuccess,
    onClose: onCloseSuccess,
    onOpen: onOpenSuccess,
  } = useDisclosure({ defaultIsOpen: false });

  // FORM MANIPULATION
  const {
    register,
    control,
    watch,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<accident>();

  const onSubmit: SubmitHandler<accident> = async (data: accident) => {
    onCloseFail();
    delete data.picture_curr;

    try {
      // PROCESS DATA
      const processedData = processFormData(data, "visitor");

      // ADD DATA
      await addData(processedData);

      // RESET FORM
      reset();
      onOpenSuccess();
    } catch (e) {
      console.log("Error adding document: ", e);
      onOpenFail();
    }
  };

  // IMAGE HANDLING
  const handleImageUpload = (e: any) => {
    let img = getValues("picture_curr");
    if (img.length > 0) {
      let files: any = [];
      if (getValues("picture_links")) {
        files = getValues("picture_links");
      }
      files.push(img[0]);
      setValue("picture_links", files);
    }
  };
  const handleImageDelete = (ind: number) => {
    const curr_links = getValues("picture_links");
    const half_init = curr_links.slice(0, ind);
    const half_fina = curr_links.slice(ind + 1);
    const full = half_init.concat(half_fina);
    setValue("picture_links", full);
  };

  return (
    <TemplateForm>
      {/* FORM FIELDS */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          align={"start"}
          spacing={8}
          divider={<StackDivider borderColor="gray.200" />}
        >
          {/* ACCIDENT SUMMARY */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.accident[lang]}
              </Heading>

              {/* DATE */}
              <Popover placement="top">
                <FormControl w="100%">
                  <FormLabel fontWeight="700">
                    {form.accident_timestamp[lang]}
                  </FormLabel>
                  <PopoverTrigger>
                    <Button
                      w="100%"
                      variant={"outline"}
                      justifyContent={"flex-start"}
                      fontWeight={"400"}
                      textColor={"gray.500"}
                    >
                      {watch("accident_timestamp")
                        ? new Date(
                            watch("accident_timestamp")?.toString() as string
                          ).toLocaleDateString()
                        : new Date().toLocaleDateString()}
                    </Button>
                  </PopoverTrigger>
                </FormControl>
                <PopoverContent w="100%">
                  <Controller
                    control={control}
                    name="accident_timestamp"
                    defaultValue={new Date()} // Set default value to today's date
                    render={({ field }) => (
                      <Calendar
                        value={field.value || new Date()} // Set default value to today's date
                        onChange={(date) => {
                          field.onChange(date || new Date()); // Set default value to today's date
                        }}
                      />
                    )}
                  />
                </PopoverContent>
              </Popover>

              {/* LOCATION SELECTOR / DISPLAYER */}
              <FormControl isInvalid={Boolean(errors.accident_location)}>
                <FormLabel fontWeight="700">
                  <Text as="span" color="red">
                    *{" "}
                  </Text>
                  {form.accident_location[lang]}{" "}
                  <Text as="span" color="green" fontSize="sm">
                    ({form.accident_location_help[lang]})
                  </Text>
                </FormLabel>
                <Box
                  border={errors.accident_location ? "2px" : "0px"}
                  borderColor={"red"}
                >
                  <Controller
                    name="accident_location"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => <LocationPicker field={field} />}
                  />
                </Box>
                <FormErrorMessage fontWeight={700}>
                  {form.field_error[lang]}
                </FormErrorMessage>
              </FormControl>

              {/* AREA DESCRIPTION */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.accident_site_details_locality[lang]}
                </FormLabel>
                <Textarea
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("accident_site_details_locality", {})}
                />
              </FormControl>

              {/* PHOTO UPLOAD */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.picture_links[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="file"
                  accept="image/*;capture=camera"
                  {...register("picture_curr", {
                    onChange: (e) => {
                      handleImageUpload(e);
                    },
                  })}
                  sx={{
                    "::file-selector-button": {
                      height: 10,
                      padding: 0,
                      mr: 4,
                      background: "none",
                      border: "none",
                      fontWeight: "bold",
                    },
                  }}
                />
              </FormControl>

              {/* DISPLAY IMAGES */}
              {Array.isArray(watch("picture_links")) &&
              watch("picture_links").length > 0 ? (
                <Wrap gap={4} w="100%">
                  {watch("picture_links").map((file: any, ind: number) => (
                    <WrapItem key={"image_" + ind} w="fit-content">
                      <Box
                        position={"relative"}
                        border="1px"
                        borderColor={"charcoal.50"}
                      >
                        <IconButton
                          onClick={() => handleImageDelete(ind)}
                          bg="burgundy.500"
                          _hover={{ bg: "burgundy.700" }}
                          variant={"brandSolid"}
                          icon={<BiTrash size={16} />}
                          size="xs"
                          aria-label={"delete_image_" + (ind + 1)}
                          position={"absolute"}
                          top={1}
                          right={2}
                        />
                        <Image
                          h="150px"
                          src={
                            typeof file === "string"
                              ? file
                              : URL.createObjectURL(file)
                          }
                        />
                      </Box>
                    </WrapItem>
                  ))}
                </Wrap>
              ) : undefined}

              {/* ACCIDENT TYPE */}
              <FormControl isInvalid={Boolean(errors.accident_type)}>
                <FormLabel fontWeight="700">
                  <Text as="span" color="red">
                    *{" "}
                  </Text>
                  {form.accident_type[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("accident_type", {
                    required: "This field is required",
                  })}
                >
                  {Object.keys(form_fields.accident_type).map((itm: any) => (
                    <option key={itm} value={itm}>
                      {form_fields.accident_type[itm][lang]}
                    </option>
                  ))}
                  <FormErrorMessage fontWeight={700}>
                    {form.field_error[lang]}
                  </FormErrorMessage>
                </Select>
              </FormControl>

              {/* ANIMAL CONDITION */}
              <FormControl isInvalid={Boolean(errors.animal_condition)}>
                <FormLabel fontWeight="700">
                  <Text as="span" color="red">
                    *{" "}
                  </Text>
                  {form.animal_condition[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("animal_condition", {
                    required: "This field is required",
                  })}
                >
                  {Object.keys(form_fields.animal_condition).map((itm: any) => (
                    <option key={itm} value={itm}>
                      {form_fields.animal_condition[itm][lang]}
                    </option>
                  ))}
                  <FormErrorMessage fontWeight={700}>
                    {form.field_error[lang]}
                  </FormErrorMessage>
                </Select>
              </FormControl>
            </VStack>
          </StandardCard>

          {/* CONTACT INFORMATION */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.personal[lang]}
              </Heading>

              {/* NAME */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.person_reporting[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="text"
                  {...register("person_reporting", {})}
                />
              </FormControl>

              {/* EMAIL */}
              <FormControl isInvalid={Boolean(errors.person_reporting_email)}>
                <FormLabel fontWeight="700">
                  {form.person_reporting_email[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="email"
                  {...register("person_reporting_email", {})}
                />
                <FormErrorMessage fontWeight={700}>
                  {form.field_error[lang]}
                </FormErrorMessage>
              </FormControl>

              {/* PHONE */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.person_reporting_phone[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="tel"
                  {...register("person_reporting_phone", {})}
                />
              </FormControl>
            </VStack>
          </StandardCard>

          {/* ADDITIONAL COMMENTS */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.comments_header[lang]}
              </Heading>

              <FormControl>
                <FormLabel fontWeight="700">{form.comments[lang]}</FormLabel>
                <Textarea
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("comments", {})}
                />
              </FormControl>
            </VStack>
          </StandardCard>

          {/* SUBMIT */}
          <VStack w="100%" gap={4}>
            <Button
              variant={"solid"}
              colorScheme="teal"
              boxShadow={"lg"}
              size="lg"
              w="100%"
              maxW="400px"
              mx="auto"
              textTransform={"uppercase"}
              type="submit"
              onClick={onOpenFail}
            >
              {form.submit[lang]}
            </Button>

            <Button
              variant={"outline"}
              colorScheme="teal"
              w="100%"
              maxW={"200px"}
              mx="auto"
              as={Link}
              to={"/"}
            >
              Go Back
            </Button>
          </VStack>
        </VStack>
      </form>

      {/* FAILURE ALERT */}
      <Alert
        status="error"
        zIndex={10000}
        position="fixed"
        bottom={0}
        left={0}
        transition={"all 0.5s ease"}
        h={isVisibleFail ? "auto" : "0px"}
        p={isVisibleFail ? "auto" : "0px"}
      >
        <HStack w="100%">
          <Spacer />
          <AlertIcon />
          <AlertTitle>{form.popup_error_title[lang]}</AlertTitle>
          <AlertDescription>{form.popup_error_text[lang]}</AlertDescription>
          <CloseButton onClick={onCloseFail} />
          <Spacer />
        </HStack>
      </Alert>

      {/* SUCCESS ALERT */}
      <Alert
        status="success"
        zIndex={10000}
        position="fixed"
        bottom={0}
        left={0}
        transition={"all 0.5s ease"}
        h={isVisibleSuccess ? "auto" : "0px"}
        p={isVisibleSuccess ? "auto" : "0px"}
      >
        <HStack w="100%">
          <Spacer />
          <AlertIcon />
          <AlertTitle>{form.popup_success_title[lang]}</AlertTitle>
          <AlertDescription>{form.popup_success_text[lang]}</AlertDescription>
          <CloseButton onClick={onCloseSuccess} />
          <Spacer />
        </HStack>
      </Alert>
    </TemplateForm>
  );
}
