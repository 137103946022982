import { Image, Text, Spacer, Stack, Center, } from "@chakra-ui/react"
import { useLangContext } from "configs/languageContext";
const mcgill_logo = require('images/mcgill.png')
const conchal_logo = require('images/conchal.png')
const salve_monos_logo = require('images/salve_monos.png')
export const PageFooter = () => {
    const { lang } = useLangContext(); // Move inside the component
    return (
        <Center borderTop={'1px'} borderColor={'charcoal.50'} w='100%' >
            <Stack maxW={{ 'base': '750px', 'lg': '970px', 'xl': '1170px' }} w='100%'
                mx='auto' direction={{ base: 'column', md: 'row' }}
                alignItems={'center'}
                p={2} spacing={{ base: 1, md: 3 }} transition={'all 0.5s ease'}>   
                <Text fontSize='small'>
                    {lang === 'es' ? 'Copyright 2023 Rescue Earth, Todos los derechos reservados' : lang === 'en' ? 'Copyright 2023 Rescue Earth, All Right Reserved' : ''}
                </Text>
                <Spacer />
                <Image src={salve_monos_logo} alt='salve_logo' h='30px' fit='scale-down' />
                <Image src={conchal_logo} alt='conchal_logo' h='30px' fit='scale-down' />
                <Image src={mcgill_logo} alt='mcgill_logo' h='30px' fit='scale-down' />
            </Stack>
        </Center>
    );
}