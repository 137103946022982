import { Button, VStack, Text, Stack, Box } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useLangContext } from "configs/languageContext";

import { translation } from "data/translations";
import { TemplatePage } from "components/layout/template";
import { StandardCard } from "components/shared/card";

const HomePage = () => {
    // LANGUAGE
    const { lang } = useLangContext();

    return (
        <TemplatePage>

            {/* DESCRIPTION */}
            <StandardCard header={translation.homepage_head[lang]}>
                <Stack spacing={8} align="center">
                    <VStack align={'center'} spacing={4} color={'gray.700'}
                        fontSize={{ base: 'md', sm: 'lg' }}>
                        <Text>{translation.homepage_text1[lang]}</Text>
                        <Text>{translation.homepage_text2[lang]}</Text>
                        <Text>{translation.homepage_text3[lang]}</Text>
                    </VStack>

                    {/* REPORT ACCIDENT */}
                    <Box as={Link} to={'./form'} target="_blank" maxW='800px' w='100%'>
                        <Button variant={'solid'} colorScheme="teal" py={8} w='100%'
                            fontSize={{ base: 'lg', sm: 'xl', md: '2xl', lg: '3xl' }}
                            boxShadow="lg">
                            {translation.homepage_report[lang]}
                        </Button>
                    </Box>

                    {/* EMPLOYEE LOGIN */}
                    <Button variant={'outline'} colorScheme="teal" w='100%' maxW={'400px'}
                        fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
                        as={Link} to={'/login'}
                        boxShadow="md">
                        {translation.homepage_login[lang]}
                    </Button>

                </Stack>
            </StandardCard>
        </TemplatePage>
    )
};

export default HomePage;