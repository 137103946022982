import { VStack, Spacer, useDisclosure, IconButton, Button, Divider } from '@chakra-ui/react';
import { useLangContext } from 'configs/languageContext';
import { translation } from 'data/translations';
import { BiEdit, BiMenu, BiBarChart, BiMap, BiUserCircle, BiCurrentLocation, BiChat } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const ButtonLink = (props: { text: string, link: string, isOpen: Boolean, icon: any }) => (
    <>
        {props.isOpen ? (
            <Button variant={'brandOutline'}
                leftIcon={props.icon}
                size='md' w='100%' p={2}
                fontSize={'md'} fontWeight={''}
                as={Link} to={props.link}
            >
                {props.text}
            </Button >
        ) : (
            <IconButton variant={'brandOutline'} icon={props.icon}
                size='md' aria-label=''
                as={Link} to={props.link}
            />
        )}
    </>
)

const PageSidebar = () => {
    const { lang } = useLangContext()
    const { isOpen, onToggle } = useDisclosure()

    return (
        <VStack top={0} h='100vh' w={'fit-content'}
            borderRight={'1px'} borderColor={'charcoal.50'}
            sx={{ position: 'sticky' }}
        >
            {/* TOGGLE AND LOGO */}
            <IconButton variant={'solid'} colorScheme='burgundy' rounded='none'
                mx={2} mr={isOpen ? 'auto' : 2} size='md' my={4}
                aria-label='sidebar toggle' onClick={onToggle} icon={<BiMenu />}
            />
            <Divider colorScheme='charcoal' />

            {/* MAIN */}
            <VStack w='100%' p={2} spacing={6}>
                <ButtonLink isOpen={isOpen} text={translation.emp_selector_data[lang]} link={"/employee/data"} icon={<BiEdit />} />
                <ButtonLink isOpen={isOpen} text={translation.emp_selector_data_chatbot[lang]} link={"/employee/data/chatbot"} icon={<BiChat />} />
                <ButtonLink isOpen={isOpen} text={translation.emp_selector_vis[lang]} link={"/employee/dashboard"} icon={<BiBarChart />} />
                <ButtonLink isOpen={isOpen} text={translation.emp_selector_clust[lang]} link={"/employee/bridge"} icon={<BiMap />} />
                <ButtonLink isOpen={isOpen} text={translation.emp_selector_opt[lang]} link={"/employee/optim"} icon={<BiCurrentLocation />} />
            </VStack>




            {/* ACCOUNT MANAGEMENT */}
            <Spacer />
            <Divider colorScheme='charcoal' />
            <VStack w='100%' p={2} spacing={6}>
                <ButtonLink isOpen={isOpen} text={translation.emp_selector_acct[lang]} link={"/employee/account"} icon={<BiUserCircle />} />
            </VStack>
        </VStack >
    )
}
export default PageSidebar;