import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Checkbox, CloseButton, FormControl, FormErrorMessage, FormLabel, HStack, Input, Select, Spacer, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { TemplatePage } from "components/layout/template";
import { StandardCard } from "components/shared/card";
import { form } from "data/translations";
import { useLangContext } from "configs/languageContext";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { collection, addDoc, doc, setDoc} from "firebase/firestore";
import { database } from "configs/firebaseConfig";
import { useDataContext } from "configs/dataContext";
import { clustering } from "data/translations";


interface formInputs {
    min_accidents: number,
    max_distance_km: number,
    use_manual_historical: boolean
}

const EmployeeBridgePage = () => {

    // LANGUAGE
    const { lang } = useLangContext()
    const { addClusterParams } = useDataContext()

    // ALERTS
    const { isOpen: isVisibleFail, onClose: onCloseFail, onOpen: onOpenFail, } = useDisclosure({ defaultIsOpen: false })
    const { isOpen: isVisibleSuccess, onClose: onCloseSuccess, onOpen: onOpenSuccess, } = useDisclosure({ defaultIsOpen: false })

    // FORM MANIPULATION
    const { register, formState: { errors }, reset, handleSubmit, setValue, getValues } = useForm<formInputs>();
    const onSubmit: SubmitHandler<formInputs> = async (data) => {
        onCloseFail()


        // THIS IS THE INTERACTION WITH THE FIREBASE
        try {
            console.log(data)
            await addClusterParams(data)
            console.log("success")
            reset()
            onOpenSuccess()
        }
        catch {
            console.log('error')
        }
        // END OF THE AREA

    }

    return (
        <TemplatePage sidebar>
            <StandardCard header={clustering.clustering_form[lang]}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack align={'start'} spacing={10}>

                        {/* MAX ACCIDENTS */}
                        <FormControl isInvalid={Boolean(errors.min_accidents)}>
                            <FormLabel fontWeight='700'>
                                <Text as='span' color='red' >* </Text>
                                {clustering.min_accident[lang]}
                            </FormLabel>
                            <Input focusBorderColor="charcoal.500" rounded='none' type="number"
                                {...register("min_accidents", { required: 'This field is required' })} />
                            <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                        </FormControl>

                        {/* MAX DISTANCE KM */}
                        <FormControl isInvalid={Boolean(errors.max_distance_km)}>
                            <FormLabel fontWeight='700'>
                                <Text as='span' color='red' >* </Text>
                                {clustering.max_dist[lang]}
                            </FormLabel>
                            <Input focusBorderColor="charcoal.500" rounded='none' type="number"
                                {...register("max_distance_km", { required: 'This field is required' })} />
                            <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                        </FormControl>

                        {/* USE HISTORICAL */}
                        <FormControl isInvalid={Boolean(errors.use_manual_historical)}>
                            <FormLabel fontWeight='700'>
                                <Text as='span' color='red' >* </Text>
                                {clustering.hist_data[lang]}
                            </FormLabel>
                            <Select focusBorderColor="charcoal.500" rounded='none'
                                {...register("use_manual_historical", { required: 'This field is required' })}>
                                <option value={'yes'}>Yes</option>
                                <option value={'yes'}>No</option>
                                <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                            </Select>
                            <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                        </FormControl>

                        {/* SUBMIT */}
                        <Button variant='brandSolid' size='lg'
                            w="100%" maxW='400px' mx='auto' textTransform={'uppercase'}
                            type="submit" onClick={onOpenFail}>
                            {form.submit[lang]}
                        </Button>

                    </VStack>

                </form>


                {/* FAILURE ALERT */}
                <Alert
                    status='error' zIndex={10000}
                    position='fixed' bottom={0} left={0}
                    transition={'all 0.5s ease'}
                    h={isVisibleFail ? 'auto' : '0px'}
                    p={isVisibleFail ? 'auto' : '0px'}>
                    <HStack w='100%' >
                        <Spacer />
                        <AlertIcon />
                        <AlertTitle>{form.popup_error_title[lang]}</AlertTitle>
                        <AlertDescription>{form.popup_error_text[lang]}</AlertDescription>
                        <CloseButton onClick={onCloseFail} />
                        <Spacer />
                    </HStack>
                </Alert>

                {/* SUCCESS ALERT */}
                <Alert
                    status='success' zIndex={10000}
                    position='fixed' bottom={0} left={0}
                    transition={'all 0.5s ease'}
                    h={isVisibleSuccess ? 'auto' : '0px'}
                    p={isVisibleSuccess ? 'auto' : '0px'}>
                    <HStack w='100%' >
                        <Spacer />
                        <AlertIcon />
                        <AlertTitle>{form.popup_success_title[lang]}</AlertTitle>
                        <AlertDescription>{form.popup_success_text[lang]}</AlertDescription>
                        <CloseButton onClick={onCloseSuccess} />
                        <Spacer />
                    </HStack>
                </Alert>
            </StandardCard>

            

            {/* MAP SELECTOR */}
            {/* <StandardCard header="Location Selector">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack bg='white' >
                        <FormControl isInvalid={Boolean(errors.accident_location)}>
                            <Box border={errors.accident_location ? "2px" : '0px'} borderColor={'red'} w='100%'>
                                <Controller name='accident_location' control={control} rules={{ required: 'This field is required' }}
                                    render={({ field }) => (<LocationPicker field={field} />)}
                                />
                            </Box>
                            <FormErrorMessage fontWeight={700}>{errors.accident_location?.message}</FormErrorMessage>
                        </FormControl>

                        <Button variant={'brandSolid'} type='submit'
                            w='100%' maxW='400px' mx='auto' >
                            Submit
                        </Button>
                    </VStack>
                </form>
            </StandardCard> */}


            {/* ATTACHED ROADS */}
            {/* <StandardCard header="Corresponding Roads">
                <VStack alignItems={'stretch'} spacing={2} bg='white' p={4}>
                    {data.length === 0
                        ? (<Text>No Roads Found</Text>)
                        : (<Text>Roads Found - See console for data</Text>)
                    }
                </VStack>
            </StandardCard> */}


        </TemplatePage >

    )
};

export default EmployeeBridgePage;