import {
  Box,
  VStack,
  Text,
  Heading,
  Image,
  IconButton,
  Button,
  Input,
  Select,
  Textarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
  useDisclosure,
  HStack,
  Spacer,
  StackDivider,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import { Wrap, WrapItem } from "@chakra-ui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import LocationPickerEmployee from "components/shared/locationPicker_employee";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { accident } from "data/data";
import { useLangContext } from "configs/languageContext";
import { form, form_fields } from "data/translations";
import { TemplateForm } from "components/layout/template";
import { StandardCard } from "components/shared/card";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "configs/dataContext";
import { Link } from "react-router-dom";
import processFormData from "./processFormData";

export default function EmployeeFormPage() {
  // LANGUAGE
  const { lang } = useLangContext();
  const { addData, editData } = useDataContext();
  const navigate = useNavigate();

  // ALERTS
  const {
    isOpen: isVisibleFail,
    onClose: onCloseFail,
    onOpen: onOpenFail,
  } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isVisibleSuccess,
    onClose: onCloseSuccess,
    onOpen: onOpenSuccess,
  } = useDisclosure({ defaultIsOpen: false });

  // FORM MANIPULATION
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm<accident>();
  const onSubmit: SubmitHandler<accident> = async (data: accident) => {
    onCloseFail();
    delete data.picture_curr;

    // ADD DATA
    if (data.id === undefined) {
      try {
        // PROCESS DATA
        const processedData = processFormData(data, "employee");

        // ADD DATA
        await addData(processedData);

        // RESET FORM
        reset();
        onOpenSuccess();
      } catch (e) {
        console.error("Error adding document: ", e);
        onOpenFail();
      }
    }

    // EDIT DATA
    else {
      try {
        // EDIT DATA
        await editData(data);

        // RESET FORM
        onOpenSuccess();
        navigate("/employee/data");
      } catch (e) {
        console.error("Error updating document: ", e);
        onOpenFail();
      }
    }
  };

  // FORM EDITING
  const location = useLocation();
  useEffect(() => {
    if (location.state !== null) {
      setValue("id", location.state.id);
      setValue("submission_type", location.state.submission_type);
      setValue("validation_status", location.state.validation_status);
      setValue("source", location.state.source);

      setValue("person_reporting", location.state.person_reporting);
      setValue("person_reporting_email", location.state.person_reporting_email);
      setValue("person_reporting_phone", location.state.person_reporting_phone);
      setValue(
        "person_attending_accident",
        location.state.person_attending_accident
      );
      setValue(
        "accident_timestamp",
        new Date(location.state.accident_timestamp.seconds * 1000)
      );

      setValue("accident_location", location.state.accident_location);

      setValue("accident_type", location.state.accident_type);
      setValue("accident_event_details", location.state.accident_event_details);

      setValue(
        "accident_required_measures",
        location.state.accident_required_measures
      );
      setValue("picture_links", location.state.picture_links);

      setValue("animal_condition", location.state.animal_condition);
      setValue("animal_species", location.state.animal_species);
      setValue("animal_sex", location.state.animal_sex);
      setValue("animal_age", location.state.animal_age);

      setValue(
        "transportation_performed",
        location.state.transportation_performed
      );
      setValue(
        "transportation_destination",
        location.state.transportation_destination
      );
      setValue(
        "transportation_destination_details",
        location.state.transportation_destination_details
      );

      setValue("accident_site_details", location.state.accident_site_details);
      setValue(
        "electrocution_pole_number",
        location.state.electrocution_pole_number
      );
      setValue("road_kilometer_number", location.state.road_kilometer_number);
      setValue(
        "accident_site_details_locality",
        location.state.accident_site_details_locality
      );
      setValue(
        "accident_site_details_additional",
        location.state.accident_site_details_additional
      );

      setValue("SITADA", location.state.SITADA);
      setValue(
        "electricity_management_number",
        location.state.electricity_management_number
      );
      setValue(
        "salvemonos_letter_number",
        location.state.salvemonos_letter_number
      );
      setValue("comments", location.state.comments);
    }
  }, [location]);

  // IMAGE HANDLING
  const handleImageUpload = (e: any) => {
    let img = getValues("picture_curr");
    if (img.length > 0) {
      let files: any = [];
      if (getValues("picture_links")) {
        files = getValues("picture_links");
      }
      files.push(img[0]);
      setValue("picture_links", files);
    }
  };
  const handleImageDelete = (ind: number) => {
    const curr_links = getValues("picture_links");
    const half_init = curr_links.slice(0, ind);
    const half_fina = curr_links.slice(ind + 1);
    const full = half_init.concat(half_fina);
    setValue("picture_links", full);
  };

  return (
    <TemplateForm>
      {/* FORM FIELDS */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          align={"start"}
          spacing={8}
          divider={<StackDivider borderColor="gray.200" />}
        >
          {/* CONTACT INFORMATION */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.personal[lang]}
              </Heading>

              {/* NAME */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.person_reporting[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="text"
                  {...register("person_reporting", {})}
                />
              </FormControl>

              {/* EMAIL */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {/* <Text as='span' color='red' >* </Text> */}
                  {form.person_reporting_email[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="email"
                  {...register("person_reporting_email")}
                />
                {/* <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage> */}
              </FormControl>

              {/* PHONE */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.person_reporting_phone[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="tel"
                  {...register("person_reporting_phone", {})}
                />
              </FormControl>

              {/* PERSON ATTENDING SCENE */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.person_attending_accident[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("person_attending_accident", {})}
                />
              </FormControl>
            </VStack>
          </StandardCard>

          {/* ACCIDENT DETAILS */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.accident[lang]}
              </Heading>

              {/* DATE */}
              <Popover placement="top">
                <FormControl w="100%">
                  <FormLabel fontWeight="700">
                    {form.accident_timestamp[lang]}
                  </FormLabel>
                  <PopoverTrigger>
                    <Button
                      w="100%"
                      variant={"outline"}
                      justifyContent={"flex-start"}
                      fontWeight={"400"}
                      textColor={"gray.500"}
                    >
                      {watch("accident_timestamp")
                        ? new Date(
                            watch("accident_timestamp")?.toString() as string
                          ).toLocaleDateString()
                        : new Date().toLocaleDateString()}
                    </Button>
                  </PopoverTrigger>
                </FormControl>
                <PopoverContent w="100%">
                  <Controller
                    control={control}
                    name="accident_timestamp"
                    defaultValue={new Date()} // Set default value to today's date
                    render={({ field }) => (
                      <Calendar
                        value={field.value || new Date()} // Set default value to today's date
                        onChange={(date) => {
                          field.onChange(date || new Date()); // Set default value to today's date
                        }}
                      />
                    )}
                  />
                </PopoverContent>
              </Popover>

              {/* LOCATION SELECTOR / DISPLAYER */}
              <FormControl
              // isInvalid={Boolean(errors.accident_location)}
              >
                <FormLabel fontWeight="700">
                  {/* <Text as='span' color='red' >* </Text> */}
                  {form.accident_location[lang]}
                </FormLabel>
                <Box
                  border={errors.accident_location ? "2px" : "0px"}
                  borderColor={"red"}
                >
                  <Controller
                    name="accident_location"
                    control={control}
                    // rules={{ required: 'This field is required' }}
                    render={({ field }) => <LocationPickerEmployee field={field} />}
                  />
                </Box>
                {/* <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage> */}
              </FormControl>

              {/* AREA DESCRIPTION - LOCALITY */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.accident_site_details_locality[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("accident_site_details_locality", {})}
                />
              </FormControl>

              {/* PHOTO UPLOAD */}
              <FormControl
              // isInvalid={Boolean(errors.picture_curr)}
              >
                <FormLabel fontWeight="700">
                  {/* <Text as='span' color='red' >* </Text> */}
                  {form.picture_links[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  type="file"
                  accept="image/*;capture=camera"
                  {...register("picture_curr", {
                    onChange: (e) => {
                      handleImageUpload(e);
                    },
                    // validate: () => {
                    //     return (Array.isArray(getValues('picture_links')) && getValues('picture_links').length > 0)
                    // }
                  })}
                  sx={{
                    "::file-selector-button": {
                      height: 10,
                      padding: 0,
                      mr: 4,
                      background: "none",
                      border: "none",
                      fontWeight: "bold",
                    },
                  }}
                />
                {/* <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage> */}
              </FormControl>

              {/* DISPLAY IMAGES */}
              {Array.isArray(watch("picture_links")) &&
              watch("picture_links").length > 0 ? (
                <Wrap gap={4} w="100%">
                  {watch("picture_links").map((file: any, ind: number) => (
                    <WrapItem key={"image_" + ind} w="fit-content">
                      <Box
                        position={"relative"}
                        border="1px"
                        borderColor={"charcoal.50"}
                      >
                        <IconButton
                          onClick={() => handleImageDelete(ind)}
                          bg="burgundy.500"
                          _hover={{ bg: "burgundy.700" }}
                          variant={"brandSolid"}
                          icon={<BiTrash size={16} />}
                          size="xs"
                          aria-label={"delete_image_" + (ind + 1)}
                          position={"absolute"}
                          top={1}
                          right={2}
                        />
                        <Image
                          h="150px"
                          src={
                            typeof file === "string"
                              ? file
                              : URL.createObjectURL(file)
                          }
                        />
                      </Box>
                    </WrapItem>
                  ))}
                </Wrap>
              ) : undefined}

              {/* ACCIDENT TYPE */}
              <FormControl
              // isInvalid={Boolean(errors.accident_type)}
              >
                <FormLabel fontWeight="700">
                  {/* <Text as='span' color='red' >* </Text> */}
                  {form.accident_type[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register(
                    "accident_type"
                    // { required: 'This field is required' }
                  )}
                >
                  {Object.keys(form_fields.accident_type).map((itm: any) => (
                    <option key={itm} value={itm}>
                      {form_fields.accident_type[itm][lang]}
                    </option>
                  ))}
                  {/* <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage> */}
                </Select>
              </FormControl>

              {/* ACCIDENT DESCRIPTION */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.accident_event_details[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("accident_event_details", {})}
                />
              </FormControl>

              {/* REQUIRED MEASURES */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.accident_required_measures[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("accident_required_measures", {})}
                >
                  {Object.keys(form_fields.accident_required_measures).map(
                    (itm: any) => (
                      <option key={itm} value={itm}>
                        {form_fields.accident_required_measures[itm][lang]}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            </VStack>
          </StandardCard>

          {/* ANIMAL DETAILS */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.animal[lang]}
              </Heading>

              {/* ANIMAL CONDITION */}
              <FormControl
              // isInvalid={Boolean(errors.animal_condition)}
              >
                <FormLabel fontWeight="700">
                  {/* <Text as='span' color='red' >* </Text> */}
                  {form.animal_condition[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register(
                    "animal_condition"
                    // { required: 'This field is required' }
                  )}
                >
                  {Object.keys(form_fields.animal_condition).map((itm: any) => (
                    <option key={itm} value={itm}>
                      {form_fields.animal_condition[itm][lang]}
                    </option>
                  ))}
                  {/* <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage> */}
                </Select>
              </FormControl>

              {/* ANIMAL SPECIES */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.animal_species[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("animal_species", {})}
                >
                  {Object.keys(form_fields.animal_species).map((itm: any) => (
                    <option key={itm} value={itm}>
                      {form_fields.animal_species[itm]["es"]}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* ANIMAL SEX */}
              <FormControl>
                <FormLabel fontWeight="700">{form.animal_sex[lang]}</FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("animal_sex", {})}
                >
                  {Object.keys(form_fields.animal_sex).map((itm: any) => (
                    <option key={itm} value={itm}>
                      {form_fields.animal_sex[itm][lang]}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* ANIMAL AGE */}
              <FormControl>
                <FormLabel fontWeight="700">{form.animal_age[lang]}</FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("animal_age", {})}
                >
                  {Object.keys(form_fields.animal_age).map((itm: any) => (
                    <option key={itm} value={itm}>
                      {form_fields.animal_age[itm][lang]}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </StandardCard>

          {/* TRANSPORTATION DETAILS */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.transport[lang]}
              </Heading>

              {/* TRANSPORTATION PERFORMED */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.transportation_performed[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("transportation_performed", {})}
                >
                  {Object.keys(form_fields.transportation_performed).map(
                    (itm: any) => (
                      <option key={itm} value={itm}>
                        {form_fields.transportation_performed[itm][lang]}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>

              {/* TRANSPORTATION DESTINATION */}
              {watch("transportation_performed")?.toString() === "yes" && (
                <FormControl>
                  <FormLabel fontWeight="700">
                    {form.transportation_destination[lang]}
                  </FormLabel>
                  <Select
                    focusBorderColor="charcoal.500"
                    rounded="none"
                    {...register("transportation_destination", {})}
                  >
                    {Object.keys(form_fields.transportation_destination).map(
                      (itm: any) => (
                        <option key={itm} value={itm}>
                          {form_fields.transportation_destination[itm][lang]}
                        </option>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

              {/* TRANSPORTATION DETAILS */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.transportation_destination_details[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("transportation_destination_details", {})}
                />
              </FormControl>
            </VStack>
          </StandardCard>

          {/* AREA DETAILS */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.area[lang]}
              </Heading>

              {/* ACCIDENT SITE */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.accident_site_details[lang]}
                </FormLabel>
                <Select
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("accident_site_details", {})}
                >
                  {Object.keys(form_fields.accident_site_details).map(
                    (itm: any) => (
                      <option key={itm} value={itm}>
                        {form_fields.accident_site_details[itm][lang]}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>

              {/* ELECTRICITY POLE NUMBER */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.electrocution_pole_number[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("electrocution_pole_number", {})}
                />
              </FormControl>

              {/* RAOD KILOMETER NUMBER */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.road_kilometer_number[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("road_kilometer_number", {})}
                />
              </FormControl>

              {/* AREA DESCRIPTION - ADDITIONAL */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.accident_site_details_additional[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("accident_site_details_additional", {})}
                />
              </FormControl>
            </VStack>
          </StandardCard>

          {/* ADDITIONAL */}
          <StandardCard
            {...{ borderWidth: 4, shadow: "none", borderColor: "dusk" }}
          >
            <VStack spacing={4}>
              <Heading mr="auto" size={"lg"}>
                {form.additional[lang]}
              </Heading>

              {/* SITADA */}
              <FormControl>
                <FormLabel fontWeight="700">{form.SITADA[lang]}</FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("SITADA", {})}
                />
              </FormControl>

              {/* Electrification management number */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.electricity_management_number[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("electricity_management_number", {})}
                />
              </FormControl>

              {/* SalveMonos letter number */}
              <FormControl>
                <FormLabel fontWeight="700">
                  {form.salvemonos_letter_number[lang]}
                </FormLabel>
                <Input
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("salvemonos_letter_number", {})}
                />
              </FormControl>

              {/* COMMENTS */}
              <FormControl>
                <FormLabel fontWeight="700">{form.comments[lang]}</FormLabel>
                <Textarea
                  focusBorderColor="charcoal.500"
                  rounded="none"
                  {...register("comments", {})}
                />
              </FormControl>
            </VStack>
          </StandardCard>

          {/* SUBMIT */}
          <VStack w="100%" gap={4}>
            <Button
              variant={"solid"}
              colorScheme="teal"
              boxShadow={"lg"}
              size="lg"
              w="100%"
              maxW="400px"
              mx="auto"
              textTransform={"uppercase"}
              type="submit"
              onClick={onOpenFail}
            >
              {form.submit[lang]}
            </Button>

            <Button
              variant={"outline"}
              colorScheme="teal"
              w="100%"
              maxW={"200px"}
              mx="auto"
              as={Link}
              to={"/"}
            >
              Go Back
            </Button>
          </VStack>
        </VStack>
      </form>

      {/* FAILURE ALERT */}
      <Alert
        status="error"
        zIndex={10000}
        position="fixed"
        bottom={0}
        left={0}
        transition={"all 0.5s ease"}
        h={isVisibleFail ? "auto" : "0px"}
        p={isVisibleFail ? "auto" : "0px"}
      >
        <HStack w="100%">
          <Spacer />
          <AlertIcon />
          <AlertTitle>{form.popup_error_title[lang]}</AlertTitle>
          <AlertDescription>{form.popup_error_text[lang]}</AlertDescription>
          <CloseButton onClick={onCloseFail} />
          <Spacer />
        </HStack>
      </Alert>

      {/* SUCCESS ALERT */}
      <Alert
        status="success"
        zIndex={10000}
        position="fixed"
        bottom={0}
        left={0}
        transition={"all 0.5s ease"}
        h={isVisibleSuccess ? "auto" : "0px"}
        p={isVisibleSuccess ? "auto" : "0px"}
      >
        <HStack w="100%">
          <Spacer />
          <AlertIcon />
          <AlertTitle>{form.popup_success_title[lang]}</AlertTitle>
          <AlertDescription>{form.popup_success_text[lang]}</AlertDescription>
          <CloseButton onClick={onCloseSuccess} />
          <Spacer />
        </HStack>
      </Alert>
    </TemplateForm>
  );
}
