import { createContext, useContext } from 'react';
import { useLocalStorage } from './authLocalStorage';

const LangContex = createContext();

export function useLangContext() {
    return useContext(LangContex)
}

export function LangProvider({ children }) {
    const [lang, setLang] = useLocalStorage('language', 'en')

    const value = {
        lang,
        setLang
    }

    return (
        <LangContex.Provider value={value}>
            {children}
        </LangContex.Provider>
    )
}