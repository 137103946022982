import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Spacer,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TemplatePage } from 'components/layout/template';
import { StandardCard } from 'components/shared/card';
import { form } from 'data/translations';
import { useLangContext } from 'configs/languageContext';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  // distinct ,

} from 'firebase/firestore';
import { database } from 'configs/firebaseConfig';
import { useDataContext } from 'configs/dataContext';
import { optimization } from "data/translations";

interface formInputs {
  model_id: string;
  cluster_number: number;
}

interface ClusteringResult {
  ID: string;
  // Date: string;
  Label: number;
  Count: number;
  // Add other variables as needed
}

const EmployeeOptimPage = () => {
  // LANGUAGE
  const { lang } = useLangContext();
  const { addOptimizationParams } = useDataContext();

  // ALERTS
  const { isOpen: isVisibleFail, onClose: onCloseFail, onOpen: onOpenFail } =
    useDisclosure({ defaultIsOpen: false });
  const { isOpen: isVisibleSuccess, onClose: onCloseSuccess, onOpen: onOpenSuccess } =
    useDisclosure({ defaultIsOpen: false });

  // FORM MANIPULATION
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<formInputs>();

  const [uniqueIDs, setUniqueIDs] = useState<string[]>([]);
  const [selectedID, setSelectedID] = useState<string | undefined>(undefined);

  const [clusterData, setClusterData] = useState<ClusteringResult[]>([]);

  useEffect(() => {
    // Fetch unique IDs from the collection
    const fetchData = async () => {
      const result: string[] = [];
      // const q = query(collection(database, 'clustering_results'), orderBy('ID'), limit(100)); // Adjust the query as needed
      const q = query(collection(database, 'clustering_results')); // Adjust the query as needed

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data() as ClusteringResult;
        if (!result.includes(data.ID)) {
          result.push(data.ID);
        }
      });

      setUniqueIDs(result);
    };

    fetchData();
  }, []);

  const handleIDChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedID(selectedValue);

    // Set the selectedValue as the model_id
    setValue('model_id', selectedValue);
  };

  const onSubmit: SubmitHandler<formInputs> = async (data) => {
    onCloseFail();

    // THIS IS THE INTERACTION WITH THE FIREBASE
    try {
      console.log(data);
      await addOptimizationParams(data);
      console.log('success');
      reset();
      onOpenSuccess();
    } catch {
      console.log('error');
    }
    // END OF THE AREA
  };

  useEffect(() => {
    // Fetch data for the selected ID
    const fetchData = async () => {
      if (selectedID) {
        const q = query(
          collection(database, 'clustering_results'),
          where('ID', '==', selectedID)
        );
  
        const querySnapshot = await getDocs(q);
        let labelCounts: { [label: number]: number } = {};
  
        querySnapshot.forEach((doc) => {
          const data = doc.data() as ClusteringResult;
          // Count labels for the specific ID
          if (data.Label in labelCounts) {
            labelCounts[data.Label]++;
          } else {
            labelCounts[data.Label] = 1;
          }
        });
  
        const clusteredData = Object.keys(labelCounts).map((label) => ({
          ID: selectedID,
          Label: parseInt(label),
          Count: labelCounts[parseInt(label)],
        }));
  
        setClusterData(clusteredData);
      }
    };
  
    fetchData();
  }, [selectedID]);

  return (
    <TemplatePage sidebar>
      <StandardCard header={optimization.optimization_form[lang]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack align="start" spacing={10}>
            {/* MODEL RESULTS */}
            <FormControl isInvalid={Boolean(errors.model_id)}>
  <FormLabel fontWeight="700">
    <Text as="span" color="red">
      *{' '}
    </Text>
    {optimization.model_select[lang]}
  </FormLabel>
  <Select
    focusBorderColor="charcoal.500"
    rounded="none"
    onChange={handleIDChange}
    value={selectedID}
  >
    <option value="" disabled>
    {optimization.cluster_number[lang]}
    </option>
    {uniqueIDs.map((id) => (
      <option key={id} value={id}>
        {id}
      </option>
    ))}
  </Select>
  <FormErrorMessage fontWeight={700}>
    {form.field_error[lang]}
  </FormErrorMessage>
</FormControl>
            {/* CLUSTER TO RUN ON */}
            <FormControl isInvalid={Boolean(errors.cluster_number)}>
              <FormLabel fontWeight="700">
                <Text as="span" color="red">
                  *{' '}
                </Text>
                Input the cluster number to run on
              </FormLabel>
              <Input
                focusBorderColor="charcoal.500"
                rounded="none"
                type="number"
                {...register('cluster_number', { required: 'This field is required' })}
              />
              <FormErrorMessage fontWeight={700}>
                {form.field_error[lang]}
              </FormErrorMessage>
            </FormControl>

            {/* SUBMIT */}
            <Button
              variant="brandSolid"
              size="lg"
              w="100%"
              maxW="400px"
              mx="auto"
              textTransform="uppercase"
              type="submit"
              onClick={onOpenFail}
            >
              {form.submit[lang]}
            </Button>
          </VStack>
        </form>

        {/* Cluster Data Table */}
        {clusterData.length > 0 && (
  <Box maxH="200px" overflowX="auto" overflowY="auto">
    <Table variant="simple" minWidth="100%">
      <Thead>
        <Tr>
          <Th>Model ID</Th>
          <Th>Cluster number</Th>
          <Th>Count</Th>

          {/* Add headers for other variables as needed */}
        </Tr>
      </Thead>
      <Tbody>
        {clusterData.map((item, index) => (
          <Tr key={index}>
            <Td>{item.ID}</Td>
            <Td>{item.Label}</Td>
            <Td>{item.Count}</Td>

            {/* Add cells for other variables as needed */}
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
)}

        {/* FAILURE ALERT */}
        <Alert
          status="error"
          zIndex={10000}
          position="fixed"
          bottom={0}
          left={0}
          transition="all 0.5s ease"
          h={isVisibleFail ? 'auto' : '0px'}
          p={isVisibleFail ? 'auto' : '0px'}
        >
          <HStack w="100%">
            <Spacer />
            <AlertIcon />
            <AlertTitle>{form.popup_error_title[lang]}</AlertTitle>
            <AlertDescription>{form.popup_error_text[lang]}</AlertDescription>
            <CloseButton onClick={onCloseFail} />
            <Spacer />
          </HStack>
        </Alert>

        {/* SUCCESS ALERT */}
        <Alert
          status="success"
          zIndex={10000}
          position="fixed"
          bottom={0}
          left={0}
          transition="all 0.5s ease"
          h={isVisibleSuccess ? 'auto' : '0px'}
          p={isVisibleSuccess ? 'auto' : '0px'}
        >
          <HStack w="100%">
            <Spacer />
            <AlertIcon />
            <AlertTitle>{form.popup_success_title[lang]}</AlertTitle>
            <AlertDescription>{form.popup_success_text[lang]}</AlertDescription>
            <CloseButton onClick={onCloseSuccess} />
            <Spacer />
          </HStack>
        </Alert>
      </StandardCard>
    </TemplatePage>
  );
};

export default EmployeeOptimPage;
