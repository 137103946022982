import {
  Box,
  Heading,
  Button,
  Stack,
  Text,
  VStack,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useLangContext } from "configs/languageContext";
import { translation, table_content } from "data/translations";
import { TemplatePage } from "components/layout/template";
import TableAccident from "./components/tableAccident";
import { StandardCard } from "components/shared/card";
import { collection, getDocs, query } from "firebase/firestore";
import React, { useMemo, useState, useEffect } from "react";
import { database } from "../../configs/firebaseConfig";
import * as Papa from "papaparse";
import { BiDownload } from "react-icons/bi";

// interface RowData {
//     id: string
//     Busqueda: string | ""
//     CMater: string| ""
//     CSitio: string| ""
//     FixedFecha: string
//     MaterialGroup: string| ""
//     NMater: string| ""
//     NSitio: string| ""
//     Nota: string| ""
//     PesoAcum: string| ""
//     TMater: string| ""
//   }
interface RowData {
  id: string;
  accident_type: string;
  accident_location: string;
  accident_site_details_additional: string;
  accident_site_details_locality: string;
  accident_timestamp: {
    nanoseconds: number;
    seconds: number;
  };
  animal_age: string;
  animal_condition: string;
  animal_sex: string;
  animal_species: string;
  electricity_management_number: string | null;
  electrocution_pole_number: string | null;
  person_attending_accident: string | null;
  person_reporting: string | null;
  person_reporting_email: string | null;
  person_reporting_phone: string | null;
  road_kilometer_number: string | null;
  salvemonos_letter_number: string | null;
  source: string;
  submission_type: string | null;
  transportation_destination: string | null;
  transportation_destination_details: string | null;
  transportation_performed: string;
  validation_status: string;
}

const EmployeeDataPage = () => {
  const { lang } = useLangContext();
  const [refresh, setrefresh] = useState(true);
  const [data, setdata] = useState<RowData[]>([]);

  useEffect(() => {
    if (refresh) {
      getUsers();
      setrefresh(false);
    }
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh(true);
  };
  const getUsers = async () => {
    try {
      const q = query(collection(database, "accidents"));
      const querySnapshot = await getDocs(q);
      const flattenedData: RowData[] = [];

      querySnapshot.forEach((doc) => {
        const accidentData = doc.data();
        const flattenedAccidentData: RowData = {
          id: doc.id,
          accident_type: accidentData.accident_type || "",
          accident_location: Array.isArray(accidentData.accident_location)
            ? accidentData.accident_location.join(", ")
            : "",
          accident_site_details_additional:
            accidentData.accident_site_details_additional || "",
          accident_site_details_locality:
            accidentData.accident_site_details_locality || "",
          accident_timestamp: {
            nanoseconds: accidentData.accident_timestamp?.nanoseconds || 0,
            seconds: accidentData.accident_timestamp?.seconds || 0,
          },
          animal_age: accidentData.animal_age || "",
          animal_condition: accidentData.animal_condition || "",
          animal_sex: accidentData.animal_sex || "",
          animal_species: accidentData.animal_species || "",
          electricity_management_number:
            accidentData.electricity_management_number || null,
          electrocution_pole_number:
            accidentData.electrocution_pole_number || null,
          person_attending_accident:
            accidentData.person_attending_accident || null,
          person_reporting: accidentData.person_reporting || null,
          person_reporting_email: accidentData.person_reporting_email || null,
          person_reporting_phone: accidentData.person_reporting_phone || null,
          road_kilometer_number: accidentData.road_kilometer_number || null,
          salvemonos_letter_number:
            accidentData.salvemonos_letter_number || null,
          source: accidentData.source || "",
          submission_type: accidentData.submission_type || null,
          transportation_destination:
            accidentData.transportation_destination || null,
          transportation_destination_details:
            accidentData.transportation_destination_details || null,
          transportation_performed: accidentData.transportation_performed || "",
          validation_status: accidentData.validation_status || "",
        };

        flattenedData.push(flattenedAccidentData);
      });

      setdata(flattenedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //   function convertToCSV(data: RowData[]): string {
  //     const header = Object.keys(data[0]).join(",") + "\n";
  //     const rows = data.map((row: RowData) =>
  //       Object.values(row)
  //         .map((value) => (typeof value === "string" && value.includes(",") ? `"${value}"` : value))
  //         .join(",") + "\n"
  //     );
  //     return header + rows.join("");
  //   }

  function convertToCSV(data: RowData[]): string {
    const csvData = Papa.unparse(data);
    return csvData;
  }

  function handleDownloadCSV() {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const currentDate = new Date()
      .toISOString()
      .replace(/[-:.]/g, "")
      .slice(0, 15);
    const filename = `Registro_${currentDate}.csv`;
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }
  return (
    <TemplatePage sidebar>
      {/* NEW DATA & NAVIGATION */}
      <VStack alignItems={"stretch"} spacing={4}>
        <Button
          variant={"brandSolid"}
          as={Link}
          to={"/employee/form"}
          target="_blank"
          px={24}
          py={10}
          w="100%"
          m={0}
          fontSize={{ base: "lg", sm: "xl", md: "2xl", lg: "3xl" }}
        >
          {translation.homepage_report[lang]}
        </Button>
      </VStack>

      {/* DESCRIPTION */}
      <StandardCard header={translation.emp_data_title[lang]}>
        <VStack
          align={"baseline"}
          spacing={1}
          color={"green.900"}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <Text>{translation.emp_data_text1[lang]}</Text>
          {/* <Text>{translation.emp_data_text2[lang]}</Text> */}
          <Text>{translation.emp_data_text3[lang]}</Text>
          <Text>{translation.emp_data_text4[lang]}</Text>
        </VStack>

        <Box
          textAlign="center"
          mt={4}
          w="100%"
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <IconButton
            variant={"brandOutline"}
            onClick={handleDownloadCSV}
            icon={<BiDownload size={30} />}
            aria-label="download data"
          />
          <Text>{table_content.DownloadData[lang]}</Text>
        </Box>
      </StandardCard>

      {/* DATA VALIDATION */}
      <StandardCard>
        <TableAccident />
      </StandardCard>
    </TemplatePage>
  );
};

export default EmployeeDataPage;
