import ReactDOMServer from "react-dom/server";
import React, { useState } from "react";
import { Box, IconButton, Button, Stack, Input, Flex } from "@chakra-ui/react";
import L from "leaflet";
import {
  useMapEvents,
  useMap,
  MapContainer,
  LayersControl,
  TileLayer,
  Marker,
} from "react-leaflet";
import { BiCurrentLocation } from "react-icons/bi";
import { ImLocation } from "react-icons/im";

import "leaflet/dist/leaflet.css";

interface LocationProps {
  field: any;
}

const LocationPicker = (props: LocationProps) => {
  // LOCATION LOGIC
  const onClickMap = (event: L.LeafletMouseEvent) => {
    const { lat, lng } = event.latlng;
    props.field.onChange([lat, lng]);
  };

  const getCurrentLocation = (event: any) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          props.field.onChange([latitude, longitude]);
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const MapClickHandler = () => {
    const map = useMapEvents({ click: onClickMap });
    return null;
  };

  const MapChangeView = ({ center, zoom }: any) => {
    const map = useMap();
    map.flyTo(center, zoom);
    return null;
  };

  const customMarkerIcon = L.divIcon({
    html: ReactDOMServer.renderToString(<ImLocation size={32} color="green" />),
    className: "custom icon",
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  // COMPONENT
  return (
    <Flex
      bg="green.50"
      border="1px"
      h="500px"
      w="100%"
      position="relative"
      align="center"
      justify="center"
      direction="column"
    >
      <Box position="absolute" top="4" right="4" zIndex="1000">
        <IconButton
          icon={<BiCurrentLocation />}
          aria-label="Get current location"
          h="48px"
          w="48px"
          border="2px"
          borderColor="gray.400"
          onClick={getCurrentLocation}
        />
      </Box>

      <MapContainer
        center={props.field.value || [10.4, -85.4]}
        zoom={9}
        style={{ height: "100%", width: "100%" }}
      >
        {/* MAP LAYERS */}
        <LayersControl position="bottomright">
          <LayersControl.BaseLayer checked name="Street Map">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite Imagery">
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Humanitarian">
            <TileLayer url="https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
        </LayersControl>

        {/* UI ELEMENTS */}
        {props.field.value && (
          <Marker position={props.field.value} icon={customMarkerIcon} />
        )}

        {/* MAP LOGIC */}
        <MapClickHandler />
        {props.field.value && (
          <MapChangeView center={props.field.value} zoom={13} />
        )}
      </MapContainer>
      <Button
        leftIcon={<BiCurrentLocation />}
        colorScheme="green"
        size="md"
        onClick={getCurrentLocation}
        marginTop={4}
        marginBottom={4}
        width="70%"
      >
        Locate Me!
      </Button>
    </Flex>
  );
};

export default LocationPicker;
