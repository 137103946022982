import ReactDOMServer from "react-dom/server";
import React, { useState } from "react";
import { Box, IconButton, Button, Stack, Input, Flex } from "@chakra-ui/react";
import L from "leaflet";
import {
  useMapEvents,
  useMap,
  MapContainer,
  LayersControl,
  TileLayer,
  Marker,
} from "react-leaflet";
import { BiCurrentLocation } from "react-icons/bi";
import { ImLocation } from "react-icons/im";

import "leaflet/dist/leaflet.css";

interface LocationProps {
  field: any;
}

const LocationPickerEmployee = (props: LocationProps) => {
  const [manualCoordinates, setManualCoordinates] = useState({ x: "", y: "" });
  const [locationType, setLocationType] = useState("map");

  // LOCATION LOGIC
  const onClickMap = (event: L.LeafletMouseEvent) => {
    const { lat, lng } = event.latlng;
    props.field.onChange([lat, lng]);
  };

  const getCurrentLocation = (event: any) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          props.field.onChange([latitude, longitude]);
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleManualCoordinatesChange = (key: string, value: string) => {
    setManualCoordinates((prevCoordinates) => ({
      ...prevCoordinates,
      [key]: value,
    }));
  };

  const handleManualSubmit = () => {
    const x = parseFloat(manualCoordinates.x);
    const y = parseFloat(manualCoordinates.y);
    if (!isNaN(x) && !isNaN(y)) {
      props.field.onChange([x, y]);
      setLocationType("map"); // Set the selected button to "Pick from Map"
    } else {
      // Handle invalid input
    }
  };

  const MapClickHandler = () => {
    const map = useMapEvents({ click: onClickMap });
    return null;
  };

  const MapChangeView = ({ center, zoom }: any) => {
    const map = useMap();
    map.flyTo(center, zoom);
    return null;
  };

  const customMarkerIcon = L.divIcon({
    html: ReactDOMServer.renderToString(<ImLocation size={32} color="green" />),
    className: "custom icon",
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  // Button styles
  const mapButtonStyle = {
    width: "120px",
    marginRight: "16px",
    backgroundColor: locationType === "map" ? "green" : "transparent",
    color: locationType === "map" ? "white" : "black",
  };

  const manualButtonStyle = {
    width: "120px",
    backgroundColor: locationType === "manual" ? "green" : "transparent",
    color: locationType === "manual" ? "white" : "black",
  };

  // Map container style
  const mapContainerStyle = {
    height: "calc(100% - 64px)", // Adjust the height as needed
    width: "100%",
  };

  // COMPONENT
  return (
    <Flex
      bg="green.50"
      border="1px"
      h="500px"
      w="100%"
      position="relative"
      align="center"
      justify="center"
      direction="column"
    >
      <Stack direction="row" mb={2} mt={2}>
        <Button style={mapButtonStyle} onClick={() => setLocationType("map")}>
          Pick from Map
        </Button>
        <Button
          style={manualButtonStyle}
          onClick={() => setLocationType("manual")}
        >
          Enter Manually
        </Button>
      </Stack>
      {locationType === "map" && (
        <Box position="absolute" top="4" right="4" bottom="2" zIndex="1000">
          <IconButton
            icon={<BiCurrentLocation />}
            aria-label="Get current location"
            h="48px"
            w="48px"
            border="2px"
            borderColor="gray.400"
            onClick={getCurrentLocation}
          />
        </Box>
      )}

      {locationType === "manual" && (
        <Stack direction="column" spacing={4} mt={4}>
          <Input
            placeholder="Enter X coordinate"
            value={manualCoordinates.x}
            onChange={(e) => handleManualCoordinatesChange("x", e.target.value)}
          />
          <Input
            placeholder="Enter Y coordinate"
            value={manualCoordinates.y}
            onChange={(e) => handleManualCoordinatesChange("y", e.target.value)}
          />
          <Button colorScheme="green" onClick={handleManualSubmit}>
            Locate on Map
          </Button>
        </Stack>
      )}

      {locationType === "map" && (
        <>
          <MapContainer
            center={props.field.value || [10.4, -85.4]}
            zoom={9}
            style={{ height: "100%", width: "100%" }}
          >
            {/* MAP LAYERS */}
            <LayersControl position="bottomright">
              <LayersControl.BaseLayer checked name="Street Map">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite Imagery">
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Humanitarian">
                <TileLayer url="https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png" />
              </LayersControl.BaseLayer>
            </LayersControl>

            {/* UI ELEMENTS */}
            {props.field.value && (
              <Marker position={props.field.value} icon={customMarkerIcon} />
            )}

            {/* MAP LOGIC */}
            <MapClickHandler />
            {props.field.value && (
              <MapChangeView center={props.field.value} zoom={13} />
            )}
          </MapContainer>
          <Button
            leftIcon={<BiCurrentLocation />}
            colorScheme="green"
            size="md"
            onClick={getCurrentLocation}
            marginTop={4}
            marginBottom={4}
            width="70%"
          >
            Locate Me!
          </Button>
        </>
      )}
    </Flex>
  );
};

export default LocationPickerEmployee;
