import { Alert, Heading, AlertIcon, Button, FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, VStack, useDisclosure } from "@chakra-ui/react";
import { TemplateLogin } from "components/layout/template";
import { useAuthContext } from "configs/authContext";
import { useLangContext } from "configs/languageContext";
import { form, login as login_translation } from "data/translations";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BiHide, BiShow } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

interface loginD {
    email: string
    password: string
}

const LoginPage = () => {
    // Initialize
    const { lang } = useLangContext()
    const { login, currentUser } = useAuthContext()
    const [error, setError] = useState('')
    const { isOpen, onToggle } = useDisclosure()
    const navigate = useNavigate()
    useEffect(() => { if (currentUser) { navigate('/employee/data') } }, [])


    // Form Logic
    const { register, formState: { errors }, handleSubmit } = useForm<loginD>();
    const onSubmit: SubmitHandler<loginD> = async (data) => {
        try {
            setError('')
            await login(data.email, data.password)
            navigate('/employee/data')
        } catch { setError('login') }
    }

    return (
        <TemplateLogin
            heading={login_translation.welcome[lang]}
            footer={login_translation.new_account[lang]}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={4}>
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }}
                        transition={'all 0.5s ease'}>
                        {login_translation.login[lang]}
                    </Heading>

                    {/* EMAIL */}
                    <FormControl isInvalid={Boolean(errors.email)}>
                        <FormLabel fontWeight='700'>{login_translation.email[lang]}</FormLabel>
                        <Input focusBorderColor="charcoal.500" type="email" rounded='none'
                            {...register("email", { required: 'This field is required' })} />
                        <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                    </FormControl>

                    {/* PASSWORD */}
                    <FormControl isInvalid={Boolean(errors.password)}>
                        <FormLabel fontWeight='700'>{login_translation.password[lang]}</FormLabel>
                        <InputGroup>

                            <Input focusBorderColor="charcoal.500" rounded='none' type={isOpen ? 'text' : 'password'}
                                {...register("password", { required: 'This field is required' })} />
                            <InputRightElement>
                                <IconButton onClick={onToggle} size='sm' variant={'ghost'}
                                    aria-label="Show Hide Password"
                                    icon={isOpen ? <BiHide /> : <BiShow />} />
                            </InputRightElement>

                        </InputGroup>
                        <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                    </FormControl>

                    {/* LOGIN BUTTON */}
                    <Button variant={'brandSolid'} type='submit' w='100%' size={'lg'}>
                        {login_translation.login[lang]}
                    </Button>

                    {/* FORGOT PASSWORD */}
                    <Button variant={'brandOutline'}
                        w='100%' maxW={'300px'}
                        as={Link} to='/reset-password'>
                        {login_translation.forgot_password[lang]}
                    </Button>
                    <Button variant='brandOutline' mx='auto'
                            as={Link} to={"/"}>
                            Go Back
                    </Button>

                    {/* ERROR */}
                    {error == 'login' && (
                        <Alert status='error'><AlertIcon />
                            {login_translation.error_login[lang]}
                        </Alert>
                    )}

                </VStack>
            </form>
        </TemplateLogin>
    )
};

export default LoginPage;