import { IconButton, Text, Flex, Stack, Spacer, HStack, Image, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Center, Heading, VStack, useDisclosure, Link } from "@chakra-ui/react";
import { BiMenu } from "react-icons/bi";
import { AiFillFacebook, AiOutlineInstagram } from "react-icons/ai";

import { useLangContext } from "configs/languageContext";
import { LanguageSelector } from "components/shared/languageSelector"
import { header } from "data/translations";

import image from "images/monkey_ai.png";
import { BreadCrumbLinks, HeaderLinks, StandardLink } from "components/shared/links";
const mcgill_logo = require('images/mcgill.png')
const conchal_logo = require('images/conchal.png')
const salve_monos_logo = require('images/salve_monos.png')

export const SalveMonosHeader = () => {
    const { lang } = useLangContext();
    const { isOpen, onToggle } = useDisclosure()

    return (
        <Flex w='100%' direction={'column'}>

            {/* TOP HEADER */}
            <Center borderBottom={'1px'} borderColor={'charcoal.50'}>
                <Stack maxW={{ 'base': '750px', 'lg': '970px', 'xl': '1170px' }} w='100%'
                    direction={{ 'base': 'column', 'sm': 'row' }}
                    alignItems={'baseline'} transition={'all 0.5s ease'}
                    spacing={{ 'base': 1, 'sm': 4 }} px={4} py={2}>

                    <Text fontSize='small'>{header.mini[lang]}</Text>
                    <Spacer />

                    <HStack spacing={4}>
                        <StandardLink href={'https://www.facebook.com/SalveMonosCR'} isexternal={true}><AiFillFacebook /> </StandardLink>
                        <StandardLink href={'https://www.instagram.com/SalveMonosCR'} isexternal={true}><AiOutlineInstagram /> </StandardLink>
                    </HStack>

                </Stack>
            </Center>

            {/* SECOND LAYER HEADER */}
            <Center>
                <Stack spacing={4} transition={'all 0.5s ease'}
                    width={{ base: '750px', lg: '970px', xl: '1170px' }}
                    direction={'row'} alignItems={'center'} p={2}>

                    <HStack pt={4} pb={1} w={{ base: '100%', md: 'auto' }}>
                        <Image src={salve_monos_logo} alt='salve_monos_logo' h={[6, 8, 4, 6, 8]} transition={'all 0.5s ease'} />
                        <Image src={conchal_logo} alt='conchal_logo' h={[6, 8, 4, 6, 8]} transition={'all 0.5s ease'} />
                        <Image src={mcgill_logo} alt='mcgill_log' h={[6, 8, 4, 6, 8]} transition={'all 0.5s ease'} />
                    </HStack>
                    <Spacer />

                    {/* LARGE SCREEN */}
                    <HStack display={{ base: 'none', md: 'flex' }} spacing={4}><HeaderLinks /></HStack>

                    {/* SMALL SCREEN MENU TOGGLER */}
                    <IconButton variant={'solid'} colorScheme='burgundy'
                        aria-label="Show Menu" icon={<BiMenu />} onClick={onToggle}
                        display={{ base: 'flex', md: 'none' }} rounded={'none'} />
                </Stack>
            </Center>


            {/* SMALL SCREEN  */}
            <Box transition={'all 0.5s ease'} h={'auto'} maxH={isOpen ? '1000px' : '0px'} >
                <Stack alignContent={'baseline'} spacing={4} borderTop={'1px'} borderColor={'gray.200'} p={4}>
                    <HeaderLinks />
                </Stack>
            </Box>
        </Flex >
    )
}

export const MainHeader = () => {
    const { lang } = useLangContext();
    return (
        <Center h='300px' w='100%'
            backgroundPosition={'left center'} backgroundImage={image} backgroundSize={'cover'}>
            <VStack maxW={{ 'base': '750px', 'lg': '970px', 'xl': '1170px' }} w='100%'
                transition={'all 0.5s ease'}
                mx='auto' align={'baseline'} 
                >

                <Box px={2}>
                    <Heading
                        color={'white'}
                        w='max-content' py={2} borderRadius={'lg'} textTransform={'capitalize'}>
                        {header.proj[lang]}
                    </Heading>

                    <BreadCrumbLinks />
                </Box>

            </VStack>
        </Center >
    )
}


export const FormHeader = () => (
<Flex
  w="100%"
  maxW="800px"
  mx="auto"
  mb={4}
  direction={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium and larger screens
  align={{ base: "center", md: "center" }} // Center align both horizontally and vertically
  justify={{ base: "center", md: "flex-start" }} // Align logos to the left on medium and larger screens
>
  <Box
    mr={{ base: 0, md: 4 }} // Margin-right for logos on medium and larger screens
    minW={20} // Minimum width for the logo box
    minH={15} // Minimum height for the logo box
  >
    <Image src={salve_monos_logo} alt="salve_logo" w={20} />
  </Box>
  <Box
    mr={{ base: 0, md: 4 }}
    minW={20}
    minH={15}
  >
    <Image src={conchal_logo} alt="conchal_logo" w={20} />
  </Box>
  <Box
    mr={{ base: 0, md: 4 }} // Margin-right for logos on medium and larger screens
    minW={20} // Minimum width for the logo box
    minH={15} // Minimum height for the logo box
    p={{ base: "2", md: "0" }} // Add padding for small screens, no padding for medium and larger screens
  >
    <Image src={mcgill_logo} alt="mcgill_logo" w={20} />
  </Box>
  <Spacer />
  <LanguageSelector /> {/* Right-align the LanguageSelector */}
</Flex>
)

export const PageHeader = () => (
    <>
        <SalveMonosHeader />
        <MainHeader />
    </>
)