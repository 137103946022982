import { Box, Heading, Button, Stack, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useLangContext } from "configs/languageContext";
import { translation } from "data/translations";
import { TemplatePage } from "components/layout/template";
import { StandardCard } from "components/shared/card";
import { collection, getDocs, query } from "firebase/firestore";
import React, { useMemo, useState, useEffect } from "react";
import { database } from "../../../configs/firebaseConfig";
import TableChatbotAccident from "./components/tableChatbotAccident";
import { accident_chatbot } from "data/data";

const EmployeeChatbotDataPage = () => {
  const { lang } = useLangContext();

  return (
    <TemplatePage sidebar>
      {/* NEW DATA & NAVIGATION */}
      <VStack alignItems={"stretch"} spacing={4}>
        <Button
          variant={"brandSolid"}
          as={Link}
          to={"/employee/form"}
          target="_blank"
          px={24}
          py={10}
          w="100%"
          m={0}
          fontSize={{ base: "lg", sm: "xl", md: "2xl", lg: "3xl" }}
        >
          {translation.homepage_report[lang]}
        </Button>
      </VStack>

      {/* DESCRIPTION */}
      <StandardCard header={translation.emp_data_title_chatbot[lang]}>
        <VStack
          align={"baseline"}
          spacing={1}
          color={"green.900"}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <Text>{translation.emp_data_text1[lang]}</Text>
          {/* <Text>{translation.emp_data_text2[lang]}</Text> */}
          <Text>{translation.emp_data_text3[lang]}</Text>
          <Text>{translation.emp_data_text4[lang]}</Text>
        </VStack>
      </StandardCard>

      {/* DATA VALIDATION */}
      <StandardCard>
        <TableChatbotAccident />
      </StandardCard>
    </TemplatePage>
  );
};

export default EmployeeChatbotDataPage;
