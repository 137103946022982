import translation_raw from './translation/translation.json';
import header_raw from './translation/header.json';
import form_raw from './translation/form.json'
import form_fields_raw from './translation/form_fields.json'
import login_raw from './translation/login.json'
import emp_account_raw from './translation/emp_account.json'
import footer_raw from './translation/footer.json'
import table_content_raw from './translation/table_content.json'
import clustering_raw from './translation/clustering.json'
import optimization_raw from './translation/optimization.json'

export const lang_options: string[] = ['en', 'es',]
export const translation: any = translation_raw
export const header: any = header_raw
export const footer: any = footer_raw
export const login: any = login_raw
export const form: any = form_raw
export const table_content: any = table_content_raw
export const form_fields: any = form_fields_raw
export const emp_account: any = emp_account_raw
export const clustering: any = clustering_raw
export const optimization: any = optimization_raw