import { Text, Button, Menu, MenuButton, MenuList, MenuItem, Box, HStack } from "@chakra-ui/react";
import { BiWorld } from "react-icons/bi";
import { useLangContext } from "configs/languageContext";
import { header, lang_options } from "data/translations";
import { StandardLink } from "components/shared/links";


export const LanguageSelector = () => {
    // LANGUAGE LOGIC
    const { lang, setLang } = useLangContext();

    return (
        <Box px={2} py={1} border='2px' borderColor={'charcoal.500'} w='fit-content'>
            <Menu>
                <MenuButton display={'inline-block'}>
                    <StandardLink isexternal={false}>
                        <HStack><BiWorld /><Text display='inline'>{lang}</Text></HStack>
                    </StandardLink>
                </MenuButton>

                <MenuList p={2} borderRadius={0}>
                    {lang_options.map((key: string) => (
                        <MenuItem key={key} onClick={() => setLang(key)}>
                            <StandardLink isexternal={false}>{header.lang[key]}</StandardLink>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box >
    );
};
