import { Alert, AlertIcon, Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, VStack, useDisclosure } from "@chakra-ui/react";
import { TemplateLogin } from "components/layout/template";
import { useAuthContext } from "configs/authContext";
import { useLangContext } from "configs/languageContext";
import { form, login as login_translation } from "data/translations";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

interface loginD { email: string }

const LoginResetPage = () => {
    // Initialize
    const { lang } = useLangContext()
    const { resetPassword, currentUser } = useAuthContext()
    const [message, setMessage] = useState('')
    const navigate = useNavigate()
    useEffect(() => { if (currentUser) { navigate('/employee/data') } }, [])


    // Form Logic
    const { register, formState: { errors }, handleSubmit } = useForm<loginD>();
    const onSubmit: SubmitHandler<loginD> = async (data) => {
        setMessage('')
        try {
            await resetPassword(data.email)
            setMessage('success')
        } catch { setMessage('reset') }
    }

    return (
        <TemplateLogin
            heading={login_translation.welcome[lang]}>


            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={4}>
                    <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }}
                        transition={'all 0.5s ease'}>
                        {login_translation.pass_reset[lang]}
                    </Heading>

                    {/* EMAIL */}
                    <FormControl isInvalid={Boolean(errors.email)}>
                        <FormLabel fontWeight='700'>{login_translation.email[lang]}</FormLabel>
                        <Input focusBorderColor="charcoal.500" rounded={'none'}
                            type="email" {...register("email", { required: 'This field is required' })} />
                        <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                    </FormControl>

                    {/* RESET PASS */}
                    <Button variant={'brandSolid'} type='submit' w='100%' size={'lg'}>
                        {login_translation.reset_pass[lang]}
                    </Button>

                    {/* LOGIN */}
                    <Button variant={'brandOutline'}
                        w='100%' maxW='300px'
                        as={Link} to='/login'>
                        {login_translation.login[lang]}
                    </Button>

                    {/* ERROR */}
                    {message == 'reset' && (
                        <Alert status='error'><AlertIcon />
                            {login_translation.error_reset[lang]}
                        </Alert>
                    )}

                    {/* MESSAGE */}
                    {message == 'success' && (
                        <Alert status='info'><AlertIcon />
                            {login_translation.message_reset[lang]}
                        </Alert>
                    )}

                </VStack>
            </form>
        </TemplateLogin >
    )
};

export default LoginResetPage;