import { Box, Spacer, Stack, VStack, Heading } from "@chakra-ui/react";
import { TemplatePage } from "components/layout/template";
import { StandardCard } from "components/shared/card";
import { useLangContext } from "configs/languageContext";
import { translation } from "data/translations";

const EmployeeDashboardPage = () => {
    const { lang } = useLangContext()

    return (
        <TemplatePage sidebar>

            {/* DASHBOARD */}
            <StandardCard header={translation.emp_selector_vis[lang]}>
                <iframe width="100%" height="700"
                    src="https://lookerstudio.google.com/embed/reporting/6a8981f7-d5f2-4481-acba-0c565751dc27/page/p_5hcbv2zz7c" allowFullScreen={true} />
            </StandardCard>

        </TemplatePage>

    )
};

export default EmployeeDashboardPage;