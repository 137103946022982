import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "./authLocalStorage";
import { database, storage } from "configs/firebaseConfig";
import { getDownloadURL, ref, uploadBytes, listAll } from "firebase/storage";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

const ChatbotDataContext = createContext();

export function useChatbotDataContext() {
  return useContext(ChatbotDataContext);
}

export function ChatbotDataProvider({ children }) {
  const [data, setData] = useLocalStorage("data_chatbot", []);
  const [loading, setLoading] = useState(false);
  const [lastRefresh, setLastRefresh] = useLocalStorage("last_ref", new Date());

  async function getData() {
    if (!loading) {
      setLoading(true);
      const docRef = await getDocs(collection(database, "accidents_chatbot"));
      const promise = [];
      docRef.forEach((accident) => {
        promise.push({ id: accident.id, ...accident.data() });
      });
      await setData(promise);
      setLastRefresh(new Date());
      setLoading(false);
    }
  }

  async function editData(editData) {
    // UPDATE FIREBASE
    const docRef = doc(database, "accidents_chatbot", editData.id);

    await setDoc(docRef, editData);

    // UPDATE LOCAL
    let newData = data.map((row) => {
      if (row.id === editData.id) {
        return editData;
      } else {
        return row;
      }
    });
    setData(newData);
  }

  async function deleteData(delData) {
    // UPDATE FIREBASE
    await deleteDoc(doc(database, "accidents_chatbot/", delData.id));

    // UPDATE LOCAL
    let newData = data.filter((row) => {
      return row.id !== delData.id;
    });
    setData(newData);
  }

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }
  }, []);

  const value = {
    data,
    loading,
    lastRefresh,
    getData,
    editData,
    deleteData,
  };

  return (
    <ChatbotDataContext.Provider value={value}>
      {children}
    </ChatbotDataContext.Provider>
  );
}
