import { Box, Center, Container, Flex, HStack, Heading, Spacer, Text, VStack, Stack } from '@chakra-ui/react';
import { PageFooter } from 'components/layout/footer';
import { FormHeader, PageHeader } from 'components/layout/header';
import { StandardCard } from 'components/shared/card';
import { LanguageSelector } from 'components/shared/languageSelector';
import { useLangContext } from 'configs/languageContext';
import { form } from 'data/translations';
import { PropsWithChildren } from 'react';
import PageSidebar from './sidebar';

interface pageProps { sidebar: Boolean }
const defaultProps: PropsWithChildren<pageProps> = { sidebar: false }
export const TemplatePage = (props: PropsWithChildren<pageProps>) => {
    return (
        <Flex>
            {props.sidebar && <PageSidebar />}

            <VStack minH="100vh" spacing={0} w='100%' >
                <PageHeader />

                <Stack maxW={{ 'base': '750px', 'lg': '970px', 'xl': '1170px' }} w='100%'
                    mx='auto' transition={'all 0.5s ease'}
                    spacing={8} p={4} align={'base'}>
                    {props.children}
                </Stack>

                <Spacer />
                <PageFooter />

            </VStack>
        </Flex>
    )
}
TemplatePage.defaultProps = defaultProps


interface loginProps { heading?: String, footer?: String }
export const TemplateLogin = (props: PropsWithChildren<loginProps>) => (
    <Center minH="100vh" bg='white' >
        <VStack w='100%' maxW='600px' px={2}>

            <HStack justifyContent={'space-between'} w='100%'>
                {props.heading &&
                    <Heading
                        fontSize={{ base: '2xl', sm: '3xl', md: '4xl', lg: '5xl' }} transition={'all 0.5s ease'}
                        textTransform={'capitalize'} wordBreak={'break-word'}>
                        {props.heading}
                    </Heading>
                }
                <LanguageSelector />
            </HStack>

            <StandardCard>{props.children}</StandardCard>

            {props.footer &&
                <Text fontSize={18} fontWeight={'semibold'}>{props.footer}</Text>
            }

        </VStack>
    </Center>
)


export const TemplateForm = (props: PropsWithChildren) => {
    const { lang } = useLangContext();
    return (
        <Box minH="100vh" w='100%' bg='white' px={2}
            py={{ 'base': 0, 'md': 6 }}>
            <Box display={{ 'base': 'none', 'md': 'flex' }} ><FormHeader /></Box>

            {/* MAIN FORM CONTAINER */}
            <Container bg='white' maxW='800px'
                border='8px' borderColor={'charcoal.500'}
                px={6} py={{ 'base': 2, 'md': 2 }}>
                <Box display={{ 'base': 'flex', 'md': 'none' }} ><FormHeader /></Box>

                {/* FORM HEADING */}
                < Heading w='100%' textTransform={'capitalize'}
                    textAlign='center' mb={6} mt={6} >
                    {form.head[lang]}
                </Heading>

                {/* FORM FIELDS */}
                {props.children}
            </Container>
        </Box>
    )
}
