import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "configs/themeConfig";
import { PrivateRoute } from "./components/shared/privateRoute";
import { LangProvider } from "configs/languageContext";
import { AuthProvider } from "configs/authContext";
import { DataProvider } from "configs/dataContext";

import HomePage from "pages/home/homePage";
import LoginPage from "pages/login/loginPage";
import LoginResetPage from "pages/login/loginResetPage";

import PublicFormPage from "pages/form/publicFormPage";

import EmployeeDataPage from "pages/emp_portal/employeeData";
import EmployeeDashboardPage from "pages/emp_portal/employeeVis";
import EmployeeFormPage from "pages/form/employeeForm";
import EmployeeBridgePage from "pages/emp_portal/employeeBridge";
import EmployeeOptimPage from "pages/emp_portal/employeeOptim";
import EmployeeAccountPage from "pages/emp_portal/employeeAccount";
import { ChatbotDataProvider } from "configs/chatbot_dataContext";
import EmployeeChatbotDataPage from "pages/emp_portal/chatbot_table/employeeChatbotData";

export const App = () => {
  return (
    <ChatbotDataProvider>
      <DataProvider>
        <LangProvider>
          <AuthProvider>
            <ChakraProvider theme={theme}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/form" element={<PublicFormPage />} />

                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/reset-password" element={<LoginResetPage />} />

                  <Route path="/employee/form" element={<PrivateRoute />}>
                    <Route
                      path="/employee/form"
                      element={<EmployeeFormPage />}
                    />
                  </Route>

                  <Route path="/employee/data" element={<PrivateRoute />}>
                    <Route
                      path="/employee/data"
                      element={<EmployeeDataPage />}
                    />
                  </Route>

                  <Route path="/employee/data/chatbot" element={<PrivateRoute />}>
                    <Route
                      path="/employee/data/chatbot"
                      element={<EmployeeChatbotDataPage />}
                    />
                  </Route>

                  <Route path="/employee/dashboard" element={<PrivateRoute />}>
                    <Route
                      path="/employee/dashboard"
                      element={<EmployeeDashboardPage />}
                    />
                  </Route>

                  <Route path="/employee/bridge" element={<PrivateRoute />}>
                    <Route
                      path="/employee/bridge"
                      element={<EmployeeBridgePage />}
                    />
                  </Route>

                  <Route path="/employee/optim" element={<PrivateRoute />}>
                    <Route
                      path="/employee/optim"
                      element={<EmployeeOptimPage />}
                    />
                  </Route>

                  <Route path="/employee/account" element={<PrivateRoute />}>
                    <Route
                      path="/employee/account"
                      element={<EmployeeAccountPage />}
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </ChakraProvider>
          </AuthProvider>
        </LangProvider>
      </DataProvider>
    </ChatbotDataProvider>
  );
};
