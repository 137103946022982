import { useState, useRef, useEffect } from "react";
import {
  Button,
  HStack,
  IconButton,
  Text,
  Image,
  VStack,
  useDisclosure,
  Badge,
  Wrap,
  WrapItem,
  Select,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { useLangContext } from "configs/languageContext";
import { BiLinkExternal } from "react-icons/bi";
import { StandardLink } from "components/shared/links";
import { form_fields } from "data/translations";
import { useChatbotDataContext } from "configs/chatbot_dataContext";

// CELL RENDERS
export function CellText({ cell }: { cell: any }) {
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const overflowingText = useRef<any | null>(null);

  const checkOverflow = (textContainer: any | null): boolean => {
    if (textContainer)
      return textContainer.offsetWidth < textContainer.scrollWidth;
    return false;
  };

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [overflowingText?.current?.offsetWidth]);

  return (
    <HStack w="100%">
      <Text w="100%" as="span" ref={overflowingText} overflow={"clip"}>
        {cell.getValue() === null ? "--" : cell.getValue()}
      </Text>

      {overflowActive && (
        <IconButton
          size="xs"
          variant="link"
          onClick={onOpen}
          icon={<BiLinkExternal />}
          aria-label="expand_cell"
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Entry: {cell.row.original.id}</ModalHeader>
          <ModalBody>
            {" "}
            <Text>{cell.getValue()}</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="brandOutline" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}

// new code to handle undefined values in length and map
export function CellPic({ cell }: { cell: any }) {
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const overflowingText = useRef<HTMLDivElement | null>(null);

  const checkOverflow = (
    textContainer: HTMLSpanElement | null | undefined
  ): boolean => {
    if (textContainer)
      return textContainer.offsetWidth < textContainer.scrollWidth;
    return false;
  };

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [overflowingText?.current?.offsetWidth]);

  return (
    <HStack w="100%">
      console.log("cell.getValue():", cell.getValue());
      {cell.getValue() && cell.getValue().length > 0 ? (
        <VStack
          maxH="24px"
          alignItems={"start"}
          ref={overflowingText}
          overflow={"clip"}
        >
          {cell.getValue().map((link: string) => (
            <StandardLink
              key={link}
              href={link}
              isexternal={true}
              {...{ width: "100%" }}
            >
              {link}
            </StandardLink>
          ))}
        </VStack>
      ) : (
        <Text>--</Text>
      )}
      {overflowActive && (
        <IconButton
          size="xs"
          variant="link"
          onClick={onOpen}
          icon={<BiLinkExternal />}
          aria-label="expand_cell"
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"2xl"}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Entry: {cell.row.original.id}</ModalHeader>
          <ModalBody>
            <VStack>
              {/* IMAGE LINKS */}
              <VStack w="100%">
                {(() => {
                  try {
                    return cell.getValue().map((link: string) => (
                      <StandardLink
                        key={"modal_" + link}
                        href={link}
                        isexternal={true}
                      >
                        {link}
                      </StandardLink>
                    ));
                  } catch (error) {
                    // Handle the error here, or simply return an empty array to display nothing
                    // console.error("Error occurred while mapping links:", error);
                    return [];
                  }
                })()}
              </VStack>

              {/* IMAGE DISPLAY */}
              <Wrap gap={4} w="100%">
                {(() => {
                  try {
                    return cell.getValue().map((link: string) => (
                      <WrapItem key={"image_" + link} w="fit-content">
                        <Image src={link} />
                      </WrapItem>
                    ));
                  } catch (error) {
                    // Handle the error here, or simply return an empty array to display nothing
                    // console.error(
                    //   "Error occurred while mapping images:",
                    //   error
                    // );
                    return [];
                  }
                })()}
              </Wrap>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="brandOutline" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}

export function CellChoice({ cell }: { cell: any }) {
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const overflowingText = useRef<HTMLDivElement | null>(null);
  const { lang } = useLangContext();

  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer)
      return (
        textContainer.offsetWidth < textContainer.scrollWidth ||
        textContainer.offsetHeight < textContainer.scrollHeight
      );
    return false;
  };

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [overflowingText?.current?.offsetWidth]);

  return (
    <HStack w="100%">
      {typeof cell.getValue() === "string" ? (
        <Badge bg="sand._default">
          {form_fields[cell.column.id][cell.getValue()][lang]}
        </Badge>
      ) : (
        <>
          {Array.isArray(cell.getValue()) ? (
            <Wrap
              maxH="24px"
              alignItems={"start"}
              ref={overflowingText}
              overflow={"clip"}
            >
              {cell.getValue().map((item: string) => (
                <WrapItem key={item}>
                  <Badge bg="sand._default">{item}</Badge>
                </WrapItem>
              ))}
            </Wrap>
          ) : (
            <Text>--</Text>
          )}
        </>
      )}

      {overflowActive && (
        <IconButton
          size="xs"
          variant="link"
          onClick={onOpen}
          icon={<BiLinkExternal />}
          aria-label="expand_cell"
        />
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"2xl"}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Entry: {cell.row.original.id}</ModalHeader>
          <ModalBody>
            {typeof cell.getValue() === "string" ? (
              <Text variant={"subtle"}>
                {form_fields[cell.column.id][cell.getValue()][lang]}
              </Text>
            ) : (
              <>
                {Array.isArray(cell.getValue()) ? (
                  <Wrap
                    maxH="24px"
                    alignItems={"start"}
                    ref={overflowingText}
                    overflow={"clip"}
                  >
                    {cell.getValue().map((item: string) => (
                      <WrapItem key={item}>
                        <Badge>{item}</Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                ) : (
                  <Text>--</Text>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="brandOutline" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}

export function CellValid({ cell }: { cell: any }) {
  const { lang } = useLangContext();
  const { editData } = useChatbotDataContext();

  return (
    <>
      {/* <Text>{cell.getValue()}</Text> */}
      <Select
        value={cell.getValue()}
        onChange={(e) => {
          let data = cell.row.original;
          data.validation_status = e.target.value;
          editData(data);
        }}
        size="xs"
        variant={"flushed"}
        colorScheme="red"
      >
        {Object.keys(form_fields.validation).map((itm: any) => (
          <option key={itm} value={itm}>
            {form_fields.validation[itm][lang]}
          </option>
        ))}
      </Select>
    </>
  );
}
// new method for cell date for konsta
export function CellDate(cell: any) {
  const seconds = cell.renderValue()?.seconds ?? 0; // Use optional chaining and provide a default value (0 in this case)
  const date = new Date(seconds * 1000);
  // Check if date is valid before formatting
  if (!isNaN(date.getTime())) {
    return date.toLocaleDateString();
  } else {
    return "Invalid Date"; // Handle invalid date case
  }
}
