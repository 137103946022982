import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import firebase from "firebase/compat/app";

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyADN3CSvbpV4eSZz8dwbyDGv8d-NQTyjsc",
  authDomain: "coco-loco-monkey-bridges.firebaseapp.com",
  projectId: "coco-loco-monkey-bridges",
  storageBucket: "coco-loco-monkey-bridges.appspot.com",
  messagingSenderId: "538340585559",
  appId: "1:538340585559:web:237b98928d061faddef312",
  measurementId: "G-SD90R60RTY",
};

export const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const database = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const reCaptcha = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcBNsQnAAAAAC5dkd8AfgZS-YCVC1K6kPPR47JD"),
  isTokenAutoRefreshEnabled: true,
});

// if (process.env.NODE_ENV === "development") {
//   connectFirestoreEmulator(database, "localhost", 8080);
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectStorageEmulator(storage, "localhost", 9199);
// }
