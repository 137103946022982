import { accident } from "data/data";

export default function processFormData(rawData: accident, submission_type: string): accident {
  const processedData: accident = { ...rawData };

  // Set default values
  processedData.accident_timestamp =
    processedData.accident_timestamp ?? new Date();
  processedData.submission_type = submission_type;
  processedData.validation_status = "not_approved";
  processedData.source = "firebase";

  // Fill missing data with nulls
  const fieldsToNullify: (keyof accident)[] = [
    "person_reporting",
    "person_reporting_email",
    "person_reporting_phone",
    "person_attending_accident",
    "accident_type",
    "accident_event_details",
    "accident_required_measures",
    "animal_condition",
    "animal_species",
    "animal_sex",
    "animal_age",
    "transportation_performed",
    "transportation_destination",
    "transportation_destination_details",
    "accident_site_details",
    "electrocution_pole_number",
    "road_kilometer_number",
    "accident_site_details_locality",
    "accident_site_details_additional",
    "SITADA",
    "electricity_management_number",
    "salvemonos_letter_number",
    "comments",
    "picture_links",
  ];

  // Nullify fields that are undefined or null
  fieldsToNullify.forEach((field) => {
    if (processedData[field] === undefined || processedData[field] === null) {
      processedData[field] = null;
    }
  });

  return processedData;
}
