import { Center, Spacer, Stack, VStack, Heading, Text, Box, Alert, AlertIcon, Button, FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, useDisclosure, HStack } from "@chakra-ui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState,useEffect } from "react";
import { login as login_translation, emp_account, form } from "data/translations";
import { useLangContext } from "configs/languageContext";
import { useAuthContext } from "configs/authContext";
import { BiHide, BiShow } from "react-icons/bi";
import { TemplatePage } from "components/layout/template";
import { StandardCard } from "components/shared/card";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

interface loginD {
    email: string
    password: string
    password_confirm: string
}



const EmployeeAccountPage = () => {
    const { lang } = useLangContext()
    const { currentUser, logout, updateUserEmail, updateUserPassword ,watch,} = useAuthContext()
    const [error, setError] = useState('')
    const { isOpen: isOpenProf, onToggle: onToggleProf } = useDisclosure()
    const { isOpen: isOpenPass, onToggle: onTogglePass } = useDisclosure()
    const [AddEmail, setAddEmail] = useState('')
    const [AddPass, setAddPass] = useState('')
    const [confirmAddPass, setconfirmAddPass] = useState('');

    // Form Logic
    const { register, formState: { errors }, handleSubmit, reset } = useForm<loginD>();
    const onSubmit: SubmitHandler<loginD> = async (data) => {
        setError("")
        const promises = []

        if (data.password != data.password_confirm) { return setError('password') }
        if (data.email !== currentUser.email) { promises.push(updateUserEmail(data.email)) }
        if (data.password) { promises.push(updateUserPassword(data.password)) }

        Promise.all(promises).then(() => {
            setError("");
            onToggleProf();
            reset();
        }).catch((res: any) => {
            setError(res)
        })
    }

    const [showPassword, setShowPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true); // Track whether passwords match

    useEffect(() => {
        setPasswordsMatch(AddPass === confirmAddPass);
      }, [AddPass, confirmAddPass]);


      const AddUser = async () => {
        if (passwordsMatch) {
          try {
            const auth = getAuth();
            await createUserWithEmailAndPassword(auth, AddEmail, AddPass);
            // add_account_success
            // User created successfully, you can navigate or show a success message here
            Swal.fire(emp_account.add_account_success[lang], "success");
            // alert(emp_account.add_account_success[lang])
          } catch (error) {
            // setError("email", { message: "Failed to create user" });
            Swal.fire("Error", emp_account.add_account_failure[lang], "error");
            // alert(emp_account.add_account_failure[lang])
          }
        } else {
        //   setError("confirmPassword", { message: "Passwords do not match" });
          Swal.fire("Error", emp_account.error_pass[lang], "error");
        // alert(emp_account.error_pass[lang])
        }
      };



    return (
        <TemplatePage sidebar>

            {/* DESCRIPTION */}
            <StandardCard header={emp_account.header[lang]}>
                <VStack align={'baseline'} spacing={8} color={'green.900'}
                    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>

                    <Text>{emp_account.description[lang]}</Text>

                    {/* LOGOUT */}
                    <Button variant={'brandSolid'}
                        w='100%' h={12} fontSize={24} onClick={logout}
                    >
                        {login_translation.logout[lang]}
                    </Button>

                </VStack>
            </StandardCard>


            {/* PROFILE INFO */}
            <StandardCard >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={6}>

                        {/* EMAIL */}
                        <FormControl isInvalid={Boolean(errors.email)} isReadOnly={!isOpenProf}>
                            <FormLabel fontWeight='700'>{login_translation.email[lang]}</FormLabel>
                            <Input focusBorderColor="charcoal.500" type="email" rounded='none' defaultValue={currentUser.email}
                                {...register("email", { required: 'This field is required' })} />
                            <FormErrorMessage fontWeight={700}>{form.field_error[lang]}</FormErrorMessage>
                        </FormControl>

                        {/* PASSWORD */}
                        {isOpenProf && <FormControl>
                            <FormLabel fontWeight='700'>{login_translation.password[lang]}</FormLabel>
                            <InputGroup>

                                <Input focusBorderColor="charcoal.500" rounded='none' type={isOpenPass ? 'text' : 'password'}
                                    placeholder={emp_account.password_placeholder[lang]}
                                    {...register("password", {})} />
                                <InputRightElement>
                                    <IconButton onClick={onTogglePass} size='sm' variant={'ghost'}
                                        aria-label="Show Hide Password"
                                        icon={isOpenPass ? <BiHide /> : <BiShow />} />
                                </InputRightElement>

                            </InputGroup>
                        </FormControl>}

                        {/* PASSWORD CONFIRMATOIN */}
                        {isOpenProf && <FormControl>
                            <FormLabel fontWeight='700'>{login_translation.password_confirm[lang]}</FormLabel>
                            <InputGroup>

                                <Input focusBorderColor="charcoal.500" rounded='none' type={isOpenPass ? 'text' : 'password'}
                                    placeholder={emp_account.password_placeholder[lang]}
                                    {...register("password_confirm", {})} />
                                <InputRightElement>
                                    <IconButton onClick={onTogglePass} size='sm' variant={'ghost'}
                                        aria-label="Show Hide Password"
                                        icon={isOpenPass ? <BiHide /> : <BiShow />} />
                                </InputRightElement>

                            </InputGroup>
                        </FormControl>}

                        {/* UPDATE PROFILE */}
                        {!isOpenProf &&
                            <Button variant={'brandSolid'}
                                w='100%' h={12} fontSize={24}
                                onClick={() => { onToggleProf(); reset(); setError(""); }}
                            >{emp_account.update_prof[lang]}</Button>}

                        {/* SUBMIT NEW PROFILE */}
                        {isOpenProf &&
                            <VStack w='100%'>
                                <Button variant={'brandSolid'} type='submit'
                                    w='100%' h={12} fontSize={24}
                                >{emp_account.update_prof[lang]}</Button>

                                <Button variant={'brandOutline'} type='submit'
                                    w='100%' maxW='400px' h={12} fontSize={24}
                                    onClick={() => { onToggleProf(); reset(); setError(""); }}
                                >
                                    {emp_account.update_cancel[lang]}
                                </Button>
                            </VStack>}

                        {/* PASSWORD ERROR */}
                        {error == 'password' && (
                            <Alert status='error'><AlertIcon />
                                {emp_account.error_pass[lang]}
                            </Alert>
                        )}

                        {/* CREATION ERROR */}
                        {error == 'update' && (
                            <Alert status='error'><AlertIcon />
                                {emp_account.error_update[lang]}
                            </Alert>
                        )}

                    </VStack>
                </form>

            </StandardCard>
            <StandardCard>
                <VStack align={'baseline'} spacing={8} color={'green.900'}
                    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
            {/* EMAIL */}
            <FormControl isInvalid={Boolean(errors.email)} marginBottom="20px">
              <FormLabel fontSize={{ base: "m", md: "l", lg: "l" }}
              transition={"all 0.5s ease"} fontWeight="700">{login_translation.email[lang]}</FormLabel>
              <Input
                type="email"
                value={AddEmail}
                onChange={(e) => setAddEmail(e.target.value)}
                required
              />
              <FormErrorMessage fontWeight={700}>
                {errors.email?.message}
              </FormErrorMessage>
            </FormControl>
  
            {/* PASSWORD */}
          <FormControl isInvalid={Boolean(errors.password)} marginBottom="20px">
            <FormLabel fontWeight="700" fontSize={{ base: "m", md: "l", lg: "l" }}
              transition={"all 0.5s ease"}>{login_translation.password[lang]}</FormLabel>
            <InputGroup>
              <Input
                type="password"
                value={AddPass}
                onChange={(e) => setAddPass(e.target.value)}
                required
              />

            </InputGroup>
            <FormErrorMessage fontWeight={700}>
              {errors.password?.message}
            </FormErrorMessage>
          </FormControl>

          {/* CONFIRM PASSWORD */}
          <FormControl isInvalid={!passwordsMatch} marginBottom="20px">
            <FormLabel fontWeight="700" fontSize={{ base: "m", md: "l", lg: "l" }}
              transition={"all 0.5s ease"}>{login_translation.password_confirm[lang]}
              </FormLabel>
            <InputGroup>
              <Input
                type="password"
                value={confirmAddPass}
                onChange={(e) => setconfirmAddPass(e.target.value)}
                required
              />
              {/* <InputRightElement>
                <IconButton
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  variant="ghost"
                  aria-label={showPassword ? "Hide password" : "Show password"}
                  onClick={() =>
                    setShowPassword((prevShowPassword) => !prevShowPassword)
                  }
                />
              </InputRightElement> */}
            </InputGroup>
            {/* <FormErrorMessage fontWeight={700}>
              {errors.confirmPassword?.message}
            </FormErrorMessage> */}
          </FormControl >
                    <Button variant={'brandSolid'}
                        w='100%' h={12} fontSize={24} onClick={AddUser}
                    >
                        {login_translation.add_user[lang]}
                    </Button>

                </VStack>
            </StandardCard>
        </TemplatePage>
    )
};

export default EmployeeAccountPage;