import React, { useMemo, useState } from "react";
import {
  Button,
  Center,
  SimpleGrid,
  Grid,
  GridItem,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Box,
  Spacer,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  VStack,
  Switch,
  Heading,
  useDisclosure,
  Tooltip,
  Select,
  FormControl,
  FormLabel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  useMediaQuery,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import {
  useReactTable,
  Column,
  Table as TableRT,
  ColumnDef,
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  FilterFn,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { StandardCard } from "components/shared/card";
import { useDataContext } from "configs/dataContext";
import { useLangContext } from "configs/languageContext";
import { accident_chatbot } from "data/data";
import { form, table_content } from "data/translations";
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
  BiEdit,
  BiHide,
  BiTrash,
  BiRefresh,
  BiExpand,
  BiChevronDown,
  BiChevronUp,
  BiDownload,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  CellChoice,
  CellDate,
  CellPic,
  CellText,
  CellValid,
} from "./cellRenders";
import { fuzzyFilter, Filter } from "./cellFilters";
import { useChatbotDataContext } from "configs/chatbot_dataContext";
import * as Papa from "papaparse";

declare module "@tanstack/react-table" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const TableChatbotAccident = () => {
  // LANGUAGE AND DATA
  const { lang } = useLangContext();
  const { data, lastRefresh, getData, loading, deleteData } =
    useChatbotDataContext();
  const [isLargerThanSm] = useMediaQuery("(min-width: 45em)"); // Adjust the breakpoint as needed

  // DEFINE COLUMNS
  const columns = useMemo<ColumnDef<accident_chatbot>[]>(
    () => [
      {
        header: " ",
        columns: [
          {
            accessorKey: "validation_status",
            header: table_content.Validation[lang],
            sortingFn: "auto",
            filterFn: "equalsString",
            cell: CellValid,
          },
        ],
      },
      {
        header: form.personal[lang],
        columns: [
          {
            accessorKey: "phone_number",
            header: form.person_reporting_phone[lang],
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellText,
          },
        ],
      },

      {
        header: form.accident[lang],
        columns: [
          {
            accessorKey: "report_timestamp",
            header: form.accident_timestamp[lang],
            filterFn: "fuzzy",
            sortingFn: (rowA: any, rowB: any, columnId: any) => {
              const dateA = new Date(rowA.getValue(columnId).seconds);
              const dateB = new Date(rowB.getValue(columnId).seconds);
              return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
            },
            cell: CellDate,
          },
          {
            id: "latitude",
            accessorKey: "location",
            header: table_content.Latitude[lang],
            filterFn: "fuzzy",
            sortingFn: (rowA: any, rowB: any, columnId: any) => {
              let numA = rowA.getValue(columnId);
              if (numA !== null) {
                numA = numA[0];
              }

              let numB = rowB.getValue(columnId);
              if (numB !== null) {
                numB = numB[0];
              }

              return numA < numB ? 1 : numA > numB ? -1 : 0;
            },
            cell: (cell: any) => {
              const value = cell.getValue() as accident_chatbot["location"];
              return <Text>{Math.round(value.latitude * 10000) / 10000}</Text>;
            },
          },
          {
            id: "longitude",
            accessorKey: "location",
            header: table_content.Longitude[lang],
            filterFn: "fuzzy",
            sortingFn: (rowA: any, rowB: any, columnId: any) => {
              let numA = rowA.getValue(columnId);
              if (numA !== null) {
                numA = numA[1];
              }

              let numB = rowB.getValue(columnId);
              if (numB !== null) {
                numB = numB[1];
              }

              return numA < numB ? 1 : numA > numB ? -1 : 0;
            },
            cell: (cell: any) => {
              const value = cell.getValue() as accident_chatbot["location"];
              return <Text>{Math.round(value.longitude * 10000) / 10000}</Text>;
            },
          },
          {
            id: "free_text_location",
            accessorKey: "location",
            header: table_content.FreeText[lang],
            filterFn: "fuzzy",
            sortingFn: "auto",
            cell: (cell: any) => {
              const value = cell.getValue() as accident_chatbot["location"];
              return <Text>{value.free_text_location}</Text>;
            },
          },
          {
            accessorKey: "media",
            header: form.picture_links[lang],
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellPic,
          },
          {
            accessorKey: "accident_type",
            header: form.accident_type[lang],
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellChoice,
          },
          {
            accessorKey: "comments",
            header: form.accident_event_details[lang],
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellText,
          },
        ],
      },

      {
        header: form.animal[lang],
        columns: [
          {
            accessorKey: "animal_condition",
            header: form.animal_condition[lang],
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellChoice,
          },
        ],
      },
      {
        header: form.area[lang],
        columns: [
          {
            accessorKey: "transformer_number",
            header: form.electrocution_pole_number[lang],
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellText,
          },
        ],
      },

      {
        header: form.additional[lang],
        columns: [
          {
            accessorKey: "id",
            header: "ID",
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellText,
          },
          {
            accessorKey: "source",
            header: "Source",
            sortingFn: "auto",
            filterFn: "fuzzy",
            cell: CellText,
          },
        ],
      },
    ],
    []
  );

  // COLUMN VISIBILITY
  const [columnVisibility, setColumnVisibility] = useState({});

  // TABLE FULL SCREEN
  const { isOpen: isFullscreen, onToggle: onToggleFullscreen } =
    useDisclosure();

  // TABLE FILTERS

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  // DEFINE TABLE
  const [columnResizeMode, setColumnResizeMode] =
    useState<ColumnResizeMode>("onChange");
  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    state: { columnVisibility, columnFilters },

    defaultColumn: { size: 150 },
    initialState: { pagination: { pageSize: 30 } },

    filterFns: { fuzzy: fuzzyFilter },
    getCoreRowModel: getCoreRowModel(),

    // FILTERS
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),

    // SORT
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),

    // COLUMN VISIBILITY
    onColumnVisibilityChange: setColumnVisibility,

    // PAGINATION
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false,
  });

  // DELETE ALERT
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const [deletionRow, setDeletionRow] = useState<any>(null);
  const [deletionField, setDeletionField] = useState<any>(null);
  const cancelRef = React.useRef<any>();

  const flattenData = (data: accident_chatbot[]) =>
    data.map((row) => {
      const flatRow: { [key: string]: any } = { ...row };
      for (const key in row) {
        if (typeof row[key as keyof accident_chatbot] === "object") {
          flatRow[key] = JSON.stringify(row[key as keyof accident_chatbot]);
        }
      }
      return flatRow;
    });

  function downloadDataCSV() {
    const flatData = flattenData(data);
    const csvData = Papa.unparse(flatData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const currentDate = new Date()
      .toISOString()
      .replace(/[-:.]/g, "")
      .slice(0, 15);
    const filename = `Registro_${currentDate}_Chatbot.csv`;
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  // MAIN COMPONENT
  return (
    <VStack
      spacing={2}
      position={isFullscreen ? "fixed" : undefined}
      top={isFullscreen ? 0 : undefined}
      left={isFullscreen ? 0 : undefined}
      w={isFullscreen ? "100%" : undefined}
      h={isFullscreen ? "100vh" : undefined}
      p={isFullscreen ? 4 : undefined}
      bg="white"
    >
      {/* DELETE ALERT */}
      <AlertDialog
        isCentered
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isOpen={isOpenAlert}
      >
        <AlertDialogOverlay />

        <AlertDialogContent rounded={"none"}>
          <AlertDialogHeader>Delete Row?</AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogBody>
            <VStack alignItems={"start"}>
              <Text>Are you sure you want to delete this row?</Text>
              <Text>
                Enter the ID to confirm:
                <Text as="span" fontWeight={"medium"}>
                  {" "}
                  {deletionRow?.id}
                </Text>
              </Text>
              <Input
                value={deletionField}
                onChange={(e) => setDeletionField(e.target.value)}
                focusBorderColor="charcoal.500"
              ></Input>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack>
              <Button
                variant="brandOutline"
                ref={cancelRef}
                onClick={onCloseAlert}
              >
                Cancel
              </Button>
              <Button
                isDisabled={deletionField !== deletionRow?.id}
                variant="brandSolid"
                ref={cancelRef}
                onClick={() => {
                  deleteData(deletionRow);
                  setDeletionRow(null);
                  setDeletionField(null);
                  onCloseAlert();
                }}
              >
                Confirm
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* TABLE HEADER */}
      <HStack w="100%" spacing={2}>
        {/* COLUMN SELECTOR */}
        <Popover>
          <PopoverTrigger>
            <Button variant={"brandOutline"} leftIcon={<BiHide size={20} />}>
              {table_content.SelectFields[lang]}
            </Button>
          </PopoverTrigger>

          <PopoverContent maxH="400px" w="400px" rounded="none">
            <PopoverArrow />
            <PopoverCloseButton />
            <StandardCard {...{ overflowY: "scroll" }}>
              <VStack spacing={6}>
                {table.getAllColumns().map((columnGroup: any) => (
                  <VStack
                    key={"show_col_group_" + columnGroup.id}
                    alignItems={"baseline"}
                    w="100%"
                    spacing={2}
                  >
                    <Heading key={columnGroup.id} fontSize={"lg"}>
                      {columnGroup.columnDef.header}
                    </Heading>

                    {columnGroup.columns.map((column: any) => (
                      <FormControl key={"show_col_" + column.id} as={HStack}>
                        <Switch
                          size={"sm"}
                          colorScheme="burgundy"
                          isChecked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                        />
                        <FormLabel p={0} m={0}>
                          {String(column.columnDef.header)}
                        </FormLabel>
                      </FormControl>
                    ))}
                  </VStack>
                ))}
              </VStack>
            </StandardCard>
          </PopoverContent>
        </Popover>

        <Spacer />
        {/* FULLSCREEN OPTION */}
        {isLargerThanSm && (
          <Text color="charcoal.200">
            {table_content.LastRefresh[lang]}{" "}
            {new Date(lastRefresh).toLocaleString()}
          </Text>
        )}
        <Tooltip label={table_content.Fullscreen[lang]}>
          <IconButton
            variant={"brandOutline"}
            onClick={onToggleFullscreen}
            icon={<BiExpand size={20} />}
            aria-label="fullscreen"
          />
        </Tooltip>

        {/* REFRESH DATA */}
        <Tooltip label={table_content.RefreshData[lang]}>
          <IconButton
            variant={"brandOutline"}
            onClick={getData}
            icon={<BiRefresh size={20} />}
            aria-label="refresh data"
          />
        </Tooltip>

        {/* DOWNLOAD DATA */}
        <Tooltip label={table_content.DownloadData[lang]}>
          <IconButton
            variant={"brandOutline"}
            onClick={downloadDataCSV}
            icon={<BiDownload size={20} />}
            aria-label="download data"
          />
        </Tooltip>
      </HStack>

      {/* TABLE */}
      {!loading && (
        <TableContainer
          w="100%"
          border="2px"
          borderColor={"charcoal.500"}
          h={isFullscreen ? "100vh" : undefined}
          overflowY={isFullscreen ? "auto" : undefined}
        >
          <Table
            variant="striped"
            bg="white"
            size="sm"
            w={table.getCenterTotalSize()}
          >
            {/* TABLE HEADERS */}
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} whiteSpace={"normal"}>
                  <Th position={"relative"} p={2}>
                    <Box
                      position={"absolute"}
                      right={0}
                      top={0}
                      h={"100%"}
                      w={"1px"}
                      bg={"charcoal.50"}
                    />
                  </Th>

                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      position={"relative"}
                      w={header.getSize()}
                      colSpan={header.colSpan}
                      minW={header.getSize()}
                      maxW={header.getSize()}
                      py={1}
                      px={2}
                      alignItems={"baseline"}
                    >
                      <VStack spacing={1} alignItems={"start"}>
                        <HStack
                          alignItems={""}
                          cursor={"pointer"}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <Text>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Text>
                          {header.column.getIsSorted() === "asc" && (
                            <BiChevronDown size={20} />
                          )}
                          {header.column.getIsSorted() === "desc" && (
                            <BiChevronUp size={20} />
                          )}
                        </HStack>

                        {header.column.getCanFilter() && (
                          <>
                            <Spacer />
                            <Filter column={header.column} table={table} />
                          </>
                        )}
                      </VStack>

                      <Box
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        position={"absolute"}
                        right={0}
                        top={0}
                        h={"100%"}
                        cursor={"col-resize"}
                        w={header.column.getIsResizing() ? "3px" : "1px"}
                        _hover={{ w: "3px" }}
                        bg={
                          header.column.getIsResizing()
                            ? "burgundy.500"
                            : "charcoal.50"
                        }
                      />
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            {/* BODY */}
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  <Td position={"relative"} p={1} h="fit-content">
                    {/* <IconButton
                      mr={1}
                      colorScheme="orange"
                      icon={<BiEdit />}
                      aria-label="validate"
                      size="xs"
                      rounded="none"
                      as={Link}
                      to={"/employee/form/"}
                      state={row.original}
                    /> */}
                    <IconButton
                      mr={1}
                      icon={<BiTrash />}
                      aria-label="delete"
                      colorScheme="burgundy"
                      onClick={() => {
                        setDeletionRow(row.original);
                        onOpenAlert();
                      }}
                      size="xs"
                      rounded="none"
                    />
                    <Box
                      position={"absolute"}
                      right={0}
                      top={0}
                      h={"100%"}
                      w={"1px"}
                      bg={"charcoal.50"}
                    />
                  </Td>

                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      overflow={"clip"}
                      position={"relative"}
                      p={1}
                      h="fit-content"
                      minW={cell.column.getSize()}
                      maxW={cell.column.getSize()}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                      <Box
                        position={"absolute"}
                        right={0}
                        top={0}
                        h={"100%"}
                        w={"1px"}
                        bg={"charcoal.50"}
                      />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {/* TABLE FOOTER */}

      <Flex
        direction={{ base: "column", lg: "row" }}
        align="center"
        minW={{ base: "auto", lg: "500px" }}
        w="100%"
        justifyContent={{ base: "start", lg: "space-between" }} // Align leftmost, center, and rightmost
        padding={{ base: "2", lg: "0" }} // Add padding only on small screens
      >
        <Box padding="2">
          {" "}
          {/* Add padding to all boxes */}
          <HStack color="green.700">
            <Text>Show</Text>
            <Select
              bg="white"
              rounded="none"
              w="80px"
              focusBorderColor="charcoal.500"
              size="sm"
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
            >
              {[10, 30, 50, 100, 200, 500].map((pageSize) => (
                <option key={"pagesize_" + pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Select>
            <Text>Entries</Text>
          </HStack>
        </Box>

        <GridItem colSpan={2}>
          <Center>
            <Box>
              <HStack spacing={6}>
                <HStack spacing={1}>
                  <IconButton
                    icon={<BiChevronsLeft />}
                    aria-label="First Page"
                    variant={"brandOutline"}
                    size="sm"
                    onClick={() => table.setPageIndex(0)}
                  />
                  <IconButton
                    icon={<BiChevronLeft />}
                    aria-label="Previous Page"
                    variant={"brandOutline"}
                    size="sm"
                    onClick={() => table.previousPage()}
                  />
                </HStack>

                <HStack spacing={1}>
                  <Text color="green.700">
                    Page{" "}
                    <Text as="span">
                      {" "}
                      {table.getState().pagination.pageIndex + 1}{" "}
                    </Text>
                    of <Text as="span"> {table.getPageCount()} </Text>
                  </Text>
                </HStack>

                <HStack spacing={1}>
                  <IconButton
                    icon={<BiChevronRight />}
                    aria-label="Next Page"
                    variant={"brandOutline"}
                    size="sm"
                    onClick={() =>
                      table.getCanNextPage() ? table.nextPage() : null
                    }
                  />
                  <IconButton
                    icon={<BiChevronsRight />}
                    aria-label="Last Page"
                    variant={"brandOutline"}
                    size="sm"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  />
                </HStack>
              </HStack>
            </Box>
          </Center>
        </GridItem>

        <Box padding="2">
          {" "}
          {/* Add padding to all boxes */}
          <HStack spacing={6}>
            <Spacer />
            <Text color="green.700">Go to page:</Text>
            <NumberInput
              bg="white"
              w="80px"
              variant={"outline"}
              focusBorderColor="charcoal.500"
              size="sm"
              defaultValue={1}
              min={1}
              max={table.getPageCount()}
              onChange={(e) => {
                table.setPageIndex(Number(e) - 1);
              }}
            >
              <NumberInputField rounded="none" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </HStack>
        </Box>
      </Flex>
    </VStack>
  );
};

export default TableChatbotAccident;
