import { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "./authLocalStorage";
import { database, storage } from "configs/firebaseConfig";
import { getDownloadURL, ref, uploadBytes, listAll } from "firebase/storage";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

const DataContext = createContext();

export function useDataContext() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [data, setData] = useLocalStorage("data", []);
  const [loading, setLoading] = useState(false);
  const [lastRefresh, setLastRefresh] = useLocalStorage("last_ref", new Date());

  async function getData() {
    if (!loading) {
      setLoading(true);
      const docRef = await getDocs(collection(database, "accidents"));
      const promise = [];
      docRef.forEach((accident) => {
        promise.push({ id: accident.id, ...accident.data() });
      });
      await setData(promise);
      setLastRefresh(new Date());
      setLoading(false);
    }
  }

  async function addData(newData) {
    // UPDATE FIREBASE
    const docRef = doc(collection(database, "accidents"));

    // UPLOAD IMAGES
    if (newData.picture_links) {
      let photoLinks = [];
      for (var i = 0; i < newData.picture_links.length; i++) {
        const storageRef = ref(
          storage,
          "accidents/" + docRef.id + "/image_" + (i + 1) + ".jpeg"
        );
        let picRef = await uploadBytes(storageRef, newData.picture_links[i]);
        await photoLinks.push(await getDownloadURL(picRef.ref));
      }
      newData.picture_links = photoLinks;
    } else {
      // If no images are uploaded, set picture_links to empty array
      newData.picture_links = [];
    }

    await setDoc(docRef, newData);

    // UPDATE LOCAL
    let fullData = [...data, newData];
    await setData(fullData);
  }

  async function editData(editData) {
    // UPDATE FIREBASE
    const docRef = doc(database, "accidents", editData.id);
    let photoLinks = [];
    let rootRef = await ref(storage, "accidents/" + docRef.id);
    let totImages = await (await listAll(rootRef)).items.length;
    let count = 0;
    for (var i = 0; i < editData.picture_links.length; i++) {
      if (typeof editData.picture_links[i] !== "string") {
        const storageRef = ref(
          storage,
          "accidents/" +
            editData.id +
            "/image" +
            (count + 1 + totImages) +
            ".jpeg"
        );
        let picRef = await uploadBytes(storageRef, editData.picture_links[i]);
        let picUrl = await getDownloadURL(picRef.ref);
        await photoLinks.push(picUrl);
        count = count + 1;
      } else {
        photoLinks.push(editData.picture_links[i]);
      }
    }
    editData.picture_links = photoLinks;
    await setDoc(docRef, editData);

    // UPDATE LOCAL
    let newData = data.map((row) => {
      if (row.id === editData.id) {
        return editData;
      } else {
        return row;
      }
    });
    setData(newData);
  }

  async function deleteData(delData) {
    // UPDATE FIREBASE
    await deleteDoc(doc(database, "accidents/", delData.id));

    // UPDATE LOCAL
    let newData = data.filter((row) => {
      return row.id !== delData.id;
    });
    setData(newData);
  }

  async function addClusterParams(newData) {
    // UPDATE FIREBASE
    const docRef = doc(collection(database, "Clustering_Parameters"));
    await setDoc(docRef, newData);
  }

  async function addOptimizationParams(newData) {
    // UPDATE FIREBASE
    const docRef = doc(collection(database, "Optimization_Params"));
    await setDoc(docRef, newData);
  }

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }
  }, []);

  const value = {
    data,
    loading,
    lastRefresh,
    getData,
    addData,
    editData,
    deleteData,
    addClusterParams,
    addOptimizationParams,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
