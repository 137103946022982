import { Column, FilterFn, Table as TableRT } from "@tanstack/react-table"
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { Input, Select } from "@chakra-ui/react";
import { useMemo } from "react";
import { form_fields } from "data/translations";
import { useLangContext } from "configs/languageContext";

// FILTER
export const fuzzyFilter: FilterFn<any> = (row: any, columnId: any, value: any, addMeta: any) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank, })
    return itemRank.passed
}


export function FilterText({ column, table, }: { column: Column<any, unknown>, table: TableRT<any> }) {
    const columnFilterValue = column.getFilterValue()

    return (
        <Input
            size='xs' type="text"
            value={(columnFilterValue ?? '') as string}
            onChange={(value) => { column.setFilterValue(value.target.value) }}
            list={column.id + 'list'}
        />
    )
}

export function FilterChoice({ column, table, }: { column: Column<any, unknown>, table: TableRT<any> }) {
    let uniques = Array.from(new Set(Array.from(column.getFacetedUniqueValues().keys()).flat())).sort()
    const columnFilterValue = column.getFilterValue()

    return (
        <>
            <datalist id={column.id + 'list'}>
                {uniques.map((value: any, ind: any) => {
                    return (<option value={value} key={"opt_" + ind} />)
                })}
            </datalist>
            <Input
                size='xs' type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={(value) => { column.setFilterValue(value.target.value) }}
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </>
    )
}

export function FilterDate({ column, table, }: { column: Column<any, unknown>, table: TableRT<any> }) {
    return (
        <></>
    )
}

export function Filter({ column, table, }: { column: Column<any, unknown>, table: TableRT<any> }) {
    const dropdownCols: string[] = [
        'validation_status',
        'accident_type',
        'accident_required_measures',
        'animal_condition',
        'animal_species',
        'animal_sex',
        'animal_age',
        'transportation_performed',
        'transportation_destination',
        'accident_site_details',
        'source'
    ]

    const stringCols: string[] = [
        'person_reporting',
        'person_reporting_email',
        'person_reporting_phone',
        'person_attending_accident',
        'accident_event_details',
        'transportation_destination_details',
        'electrocution_pole_number',
        'road_kilometer_number',
        'accident_site_details_locality',
        'accident_site_details_additional',
        'SITADA',
        'electricity_management_number',
        'salvemonos_letter_number',
        'comments',
        'id',
    ]
    const numberCols: string[] = [
        'accident_latitude',
        'accident_longitude',
    ]
    const validCols: string[] = ['']

    if (stringCols.includes(column.id)) { return (<FilterText column={column} table={table} />) }
    else if (dropdownCols.includes(column.id)) { return (<FilterChoice column={column} table={table} />) }
    else { return (<></>) }
}